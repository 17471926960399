/* Module1.store.js */

import axios from "axios";
import iliosState from "@/ilios.store";
import store from "@/store";

const initialState = () => ({
  apiState: iliosState.INIT,
  attachmentUploadList: [],
  bodyAttachmentList: [],
  attachmentList: [],
  messageError: [],
});

// State object
const state = initialState();

// Getter functions
const getters = {
  getAttachmentUploadList: (state) => state.attachmentUploadList,
  getBodyAttachmentList: (state) => state.bodyAttachmentList,
  getAttachmentList: (state) => state.attachmentList,
  getAttachmentApiState: (state) => state.apiState,
  getMessageError: (state) => state.messageError,
};

// Actions
const actions = {
  async postNewAttachment(context, { data, token, isPublic = false }) {
    context.commit("SET_API_STATE", {
      apiState: iliosState.LOADING,
    });
    await axios
      .post(
        process.env.VUE_APP_BASE_URL + "/iliosrest/post/ir.attachment",
        {
          data: {
            name: data.name,
            datas_fname: data.datas_fname,
            mimetype: data.type,
            datas: data.filedatas,
            public: false ? isPublic : true,
            res_id: data.res_id,
            res_model: data.res_model,
          },
        },
        {
          headers: {
            "Access-Token": token,
            "Content-Type": "form/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response["status"] == 200) {
          context.dispatch("generateAttachmentAccessToken", {
            token: token,
            attachmentId: response.data.data.id,
          });
          var newObj = {
            id: response.data.data.id,
            name: data.name,
            res_id: data.res_id,
            res_model: data.res_model,
          };

          context.commit("ADD_NEW_ATTACHMENT", {
            newAttachment: response.data.data,
            newObjAttachment: newObj,
          });
        }
      })
      .catch((e) => {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
  },

  async generateAttachmentAccessToken(context, { token, attachmentId }) {
    context.commit("SET_API_STATE", {
      apiState: iliosState.LOADING,
    });

    axios({
      method: "patch",
      url:
        process.env.VUE_APP_BASE_URL +
        "/iliosrest/patch/ir.attachment/" +
        attachmentId +
        "/generate_access_token",
      headers: { "Access-Token": token },
    })
      .then((response) => {
        if (response["status"] == 200) {
          context.commit("SET_API_STATE", {
            apiState: iliosState.LOADED,
          });
        }
      })
      .catch((e) => {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
        context.commit("SET_API_STATE", {
          apiState: iliosState.ERROR,
        });
      });
  },

  async postChatAttachment(context, { data, token }) {
    let result = null;
    context.commit("SET_API_STATE", {
      apiState: iliosState.LOADING,
    });
    await axios
      .post(
        process.env.VUE_APP_BASE_URL + "/iliosrest/post/ir.attachment",
        {
          data: {
            name: data.name,
            datas_fname: data.datas_fname,
            mimetype: data.type,
            datas: data.filedatas,
            public: true,
            res_id: data.res_id,
            res_model: data.res_model,
          },
        },
        {
          headers: {
            "Access-Token": token,
            "Content-Type": "form/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response["status"] == 200) {
          result = response.data.data;
        }
      })
      .catch((e) => {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
    return result;
  },

  putAttachment(context, { payload, token, isPublic = true }) {
    state.attachmentList.forEach((id) => {
      context.commit("SET_API_STATE", {
        apiState: iliosState.LOADING,
      });
      axios
        .put(
          process.env.VUE_APP_BASE_URL + "/iliosrest/put/ir.attachment/" + id,
          {
            data: {
              res_id: payload.res_id,
              public: isPublic, //passar ID da tarefa em questão
            },
          },
          {
            headers: {
              "Access-Token": token,
              "Content-Type": "form/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response["status"] == 200) {
            context.dispatch("getAttachmentById", {
              attachmentId: id,
              token: token,
            });
            if (!isPublic) {
              context.dispatch("generateAttachmentAccessToken", {
                token: token,
                attachmentId: id,
              });
            }
          }
        })
        .catch((e) => {
          const messageError = e.response ? e.response.data.message : e;
          store.commit("Error/SET_MESSAGE_ERROR", {
            message: messageError,
          });
        });
    });
    context.commit("CLEAN_ATTACHMENT_LIST");
  },

  putIndividualAttachment(context, { payload, token, id }) {
    context.commit("SET_API_STATE", {
      apiState: iliosState.LOADING,
    });
    axios
      .put(
        process.env.VUE_APP_BASE_URL + "/iliosrest/put/ir.attachment/" + id,
        {
          data: {
            res_id: payload.res_id, //passar ID da tarefa em questão
          },
        },
        {
          headers: {
            "Access-Token": token,
            "Content-Type": "form/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response["status"] == 200) {
          context.dispatch("getAttachmentById", {
            attachmentId: id,
            token: token,
          });
          let findAttachment = state.attachmentList.findIndex(
            (el) => el.id === id
          );
          findAttachment > -1
            ? context.commit("REMOVE_ATTACHMENT_LIST", { findAttachment })
            : false;
        }
      })
      .catch((e) => {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
  },

  getAttachmentById(context, { attachmentId, token }) {
    context.commit("SET_API_STATE", {
      apiState: iliosState.LOADING,
    });
    axios
      .get(process.env.VUE_APP_BASE_URL + "/iliosrest/get/ir.attachment", {
        params: {
          db: process.env.VUE_APP_DATA_BASE,
          domain: "[('id', '=', '" + attachmentId + "')]",
          fields: "['id', 'name', 'res_id']",
          context: {
            lang: "pt_BR",
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
        headers: {
          "Content-Type": "form/x-www-form-urlencoded",
          charset: "utf-8",
          "Access-Token": token,
        },
      })
      .then((response) => {
        if (response["status"] == 200) {
          context.commit("SET_ATTACHMENT_UPLOAD_LIST", {
            data: response.data.data,
          });
        }
      })
      .catch((e) => {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
  },

  getAttachment(context, { data, token }) {
    context.commit("SET_API_STATE", {
      apiState: iliosState.LOADING,
    });
    axios
      .get(process.env.VUE_APP_BASE_URL + "/iliosrest/get/ir.attachment", {
        params: {
          db: process.env.VUE_APP_DATA_BASE,
          domain:
            "[('res_model', '=', '" +
            data.res_model +
            "'), ('res_id', 'in', [" +
            data.res_id +
            "])]",
          fields: "['id', 'name', 'res_id']",
          context: {
            lang: "pt_BR",
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
        headers: {
          "Content-Type": "form/x-www-form-urlencoded",
          charset: "utf-8",
          "Access-Token": token,
        },
      })
      .then((response) => {
        if (response["status"] == 200) {
          context.commit("SET_ATTACHMENT_UPLOAD_LIST", {
            data: response.data.data,
          });
        }
      })
      .catch((e) => {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
  },

  getIrAttachmentList(context, { irAttachmentIds, token }) {
    context.commit("SET_API_STATE", {
      apiState: iliosState.LOADING,
    });
    axios
      .get(process.env.VUE_APP_BASE_URL + "/iliosrest/get/ir.attachment", {
        params: {
          db: process.env.VUE_APP_DATA_BASE,
          domain: "[('id', 'in', [" + irAttachmentIds + "])]",
          fields: "['id', 'name', 'res_id']",
          context: {
            lang: "pt_BR",
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
        headers: {
          "Content-Type": "form/x-www-form-urlencoded",
          charset: "utf-8",
          "Access-Token": token,
        },
      })
      .then((response) => {
        if (response["status"] == 200) {
          context.commit("SET_ATTACHMENT_UPLOAD_LIST", {
            data: response.data.data,
          });
        }
      })
      .catch((e) => {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
  },

  getBodyAttachment(context, { data, token }) {
    if (context.state.bodyAttachmentList.length == 0) {
      context.commit("SET_API_STATE", {
        apiState: iliosState.LOADING,
      });
      axios
        .get(process.env.VUE_APP_BASE_URL + "/iliosrest/get/ir.attachment", {
          params: {
            db: process.env.VUE_APP_DATA_BASE,
            domain:
              "[('res_model', '=', '" +
              data.res_model +
              "'), ('res_id', '=', " +
              data.res_id +
              "), ('create_uid', '=', " +
              data.create_uid +
              ")]",
            fields: "['id', 'name', 'res_id']",
            context: {
              lang: "pt_BR",
              tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          },
          headers: {
            "Content-Type": "form/x-www-form-urlencoded",
            charset: "utf-8",
            "Access-Token": token,
          },
        })
        .then((response) => {
          if (response["status"] == 200) {
            context.commit("SET_BODY_ATTACHMENT_LIST", {
              data: response.data.data,
            });
          }
        })
        .catch((e) => {
          const messageError = e.response ? e.response.data.message : e;
          store.commit("Error/SET_MESSAGE_ERROR", {
            message: messageError,
          });
        });
    }
  },

  async unlinkAttachment(context, { token, id }) {
    let result = false;
    context.commit("SET_API_STATE", {
      apiState: iliosState.LOADING,
    });
    await axios
      .delete(
        process.env.VUE_APP_BASE_URL + "/iliosrest/delete/ir.attachment/" + id,
        {
          params: {
            context: {
              lang: "pt_BR",
              tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          },
          headers: {
            "Access-Token": token,
          },
        }
      )
      .then((response) => {
        if (response["status"] == 200) {
          result = true;
        }
      })
      .catch((e) => {
        context.commit("SET_API_STATE", {
          apiState: iliosState.LOADED,
        });
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
  },

  async deleteAttachment(context, { token, attachmentId, index, backup }) {
    let result = null;
    context.commit("SET_API_STATE", {
      apiState: iliosState.LOADING,
    });

    await axios
      .delete(
        process.env.VUE_APP_BASE_URL +
          "/iliosrest/delete/ir.attachment/" +
          attachmentId,
        {
          params: {
            context: {
              lang: "pt_BR",
              tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          },
          headers: {
            "Access-Token": token,
          },
        }
      )
      .then((response) => {
        if (response["status"] == 200) {
          result = 200;
          context.commit("DELETE_ATTACHMENT", { id: attachmentId });
        }
      })
      .catch((e) => {
        context.commit("SET_API_STATE", {
          apiState: iliosState.ERROR,
        });

        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
    if (!result) {
      context.commit("RESTORE_ATTACHMENT_BACKUP", { backup: backup });
    }
  },

  cleanBodyAttachmentList(context) {
    context.commit("CLEAN_BODY_ATTACHMENT");
  },

  cleanAttachmentList(context) {
    context.commit("CLEAN_ATTACHMENT_LIST");
  },

  cleanAttachmentUploadList(context) {
    context.commit("CLEAN_ATTACHMENT_UPLOAD_LIST");
  },

  removeAttachmentList(context, { data }) {
    context.commit("REMOVE_ATTACHMENT_LIST", { data });
  },

  async downloadAttachment(context, { token, id }) {
    await axios
      .get(process.env.VUE_APP_BASE_URL + "/iliosrest/get/ir.attachment", {
        params: {
          domain: '[("id","=","' + id + '")]',
          fields: '["name", "mimetype", "datas"]',
          fields_to_decode: '["datas"]',
        },
        headers: {
          "Access-Token": token,
          "Content-Type": "form/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        const fileUrl =
          "data:" +
          response.data.data[0].mimetype +
          ";base64," +
          response.data.data[0].datas;
        fetch(fileUrl)
          .then((response) => response.blob())
          .then((blob) => {
            var link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = response.data.data[0].name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      })
      .catch((e) => {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
  },

  reset(context) {
    context.commit("RESET");
  },
};

// Mutations
const mutations = {
  SET_ATTACHMENT_UPLOAD_LIST(state, { data }) {
    if (data)
      data.forEach((element) => {
        var attachFind = state.attachmentUploadList.findIndex(
          (attach) => attach.id == element.id
        );
        if (attachFind > -1) {
          state.attachmentUploadList.splice(attachFind, 1);
          state.attachmentUploadList.push(element);
        } else {
          state.attachmentUploadList.push(element);
        }
      });
    state.apiState = iliosState.LOADED;
  },

  SET_BODY_ATTACHMENT_LIST(state, { data }) {
    if (data)
      data.forEach((element) => {
        state.bodyAttachmentList.push(element);
      });
    state.apiState = iliosState.LOADED;
  },

  CLEAN_ATTACHMENT_UPLOAD_LIST(state) {
    state.attachmentUploadList = [];
  },

  ADD_NEW_ATTACHMENT(state, { newAttachment, newObjAttachment }) {
    state.attachmentList.push(newAttachment.id);
    state.attachmentUploadList.push(newObjAttachment);
    state.apiState = iliosState.LOADED;
  },

  REMOVE_ATTACHMENT_LIST(state, { data }) {
    state.attachmentList.splice(data, 1);
    state.apiState = iliosState.LOADED;
  },

  CLEAN_ATTACHMENT_LIST(state) {
    state.attachmentList = [];
  },

  DELETE_ATTACHMENT(state, { id }) {
    for (var index in state.attachmentUploadList) {
      if (state.attachmentUploadList[index].id == id) {
        state.attachmentUploadList.splice(index, 1);
      }
    }
    state.apiState = iliosState.LOADED;
  },

  CLEAN_BODY_ATTACHMENT(state) {
    state.bodyAttachmentList = [];
  },

  SET_API_STATE(state, { apiState }) {
    state.apiState = apiState;
  },

  SET_MESSAGE_ERROR(state, { message }) {
    state.messageError = String(message);
  },

  CLEAN_ATTACHMENT_LIST(state) {
    state.attachmentList = [];
  },

  RESTORE_ATTACHMENT_BACKUP(state, { backup }) {
    for (var attach of backup) {
      state.attachmentUploadList.push(attach);
    }
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  actions,
  state,
  getters,
  mutations,
  namespaced: true,
};
