<template>
  <v-dialog v-model="openDialog" fullscreen @keydown.esc="closeImage()">
    <v-layout class="fill-height" column style="layoutBox" align-end>
      <v-btn icon @click="closeImage()">
        <v-icon large color="white"> close </v-icon>
      </v-btn>
      <v-layout align-center justify-center style="width: 100%">
        <v-img
          contain
          :src="image"
          :max-height="viewHeight"
          :max-width="viewWidth"
        >
        </v-img>
      </v-layout>
    </v-layout>
  </v-dialog>
</template>

<script>
export default {
  name: "showImage",

  props: {
    open: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      openDialog: this.open,
    };
  },

  methods: {
    closeImage() {
      this.$emit("closeImage");
    },
  },

  computed: {
    viewHeight() {
      return window.innerHeight / 1.2;
    },
    viewWidth() {
      return window.innerWidth / 1.2;
    },
    layoutBox: () => "background-color: rgb(0,0,0,0.7);",
  },
};
</script>
