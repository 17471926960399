import Vue from "vue";
import VueRouter from "vue-router";
import ServiceView from "../views/ServiceView.vue";
import TicketView from "../views/TicketView.vue";
import ManagementView from "../views/ManagementView.vue";
import SettingView from "../views/SettingView.vue";
import LoginView from "../views/LoginView.vue";
import store from "../store";
import axios from "axios";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginView,
    meta: {
      title: "Login",
      requiresAuth: false,
    },
  },
  {
    path: "/chat/service",
    name: "Atendimento",
    component: ServiceView,
    meta: {
      title: "Atendimento",
      requiresAuth: true,
    },
  },
  {
    path: "/chat/ticket",
    name: "Ticket",
    component: TicketView,
    meta: {
      title: "Atendimento",
      requiresAuth: true,
    },
  },
  {
    path: "/chat/management",
    name: "Gerenciamento",
    component: ManagementView,
    meta: {
      title: "Gerenciamento",
      requiresAuth: true,
    },
  },
  {
    path: "/chat/setting",
    name: "Configurações",
    component: SettingView,
    meta: {
      title: "Configurações",
      requiresAuth: true,
    },
  },
];

function logout() {
  store._actions["Login/UserLogOut"][0]();
  store._actions["User/reset"][0]();
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    var ltState = "";
    var userId = "";
    await axios
      .get(process.env.VUE_APP_BASE_URL + "/iliosrest/auth/localtoken/check", {
        headers: {
          "Access-Token": localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response["status"] == 200) {
          ltState = response.data.valid;
          userId = response.data.uid;
          if (ltState & (userId == Number(localStorage.getItem("userId")))) {
            next();
          } else if (ltState & userId) {
            next();
          } else {
            store.commit("Error/SET_MESSAGE_ERROR", {
              message: "invalid access_token",
            });
          }
        }
      })
      .catch((e) => {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
  } else {
    next();
  }

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  if (nearestWithMeta) {
    nearestWithMeta.meta.metaTags
      .map((tagDef) => {
        const tag = document.createElement("meta");

        Object.keys(tagDef).forEach((key) => {
          tag.setAttribute(key, tagDef[key]);
        });

        tag.setAttribute("data-vue-router-controlled", "");

        return tag;
      })
      .forEach((tag) => document.head.appendChild(tag));
  }
});

router.afterEach(() => {
  store.commit("Panel/LOAD" , { load: true})
  setTimeout(() => {
    store.commit("Panel/LOAD", { load: false });
  }, 500);
});

export default router;
