var gVar = {
  smallText: '14px',
  mediumText: '16px',
  bigText: '18px',
  headerCardSize: '20px',
  appVersion: '1.2',
  mainColor: '#e09275',
  strongMainColor: '#403b33',
  lightMainColor: '#edebe6',
  blackColor: '#1d1d1d',
  redColor: '#da294f',
  whiteColor: '#fff',
  greyColor: '#9B9BA2',
  lightGreyColor: '#ECEFF1',
  greenColor: '#1E88E5',
  blueColor: '#85b3a3',
  orangeColor: '#E67E22',
  superia: {
    blue: '#172183',
    olive: '#85b3a3',
    darkBlue: '#000F3D',
    sand: '#D3A263',
    bronze: '#d3643b',
  },

  eventColors: {
    create: '#403b33',
    sectors: [
      { color: '#88369C', light: '#ab72b9', src: '88369C_table_chair' },
      { color: '#579EF2', light: '#89bbf5', src: '579EF2_table_chair' },
      { color: '#5ADB7B', light: '#8be5a2', src: '5ADB7B_table_chair' },
      { color: '#F2E357', light: '#f5eb89', src: 'F2E357_table_chair' },
      { color: '#ED8951', light: '#f2ac85', src: 'ED8951_table_chair' },
      { color: '#107A9C', light: '#57a1b9', src: '107A9C_table_chair' },
      { color: '#8A1D0D', light: '#ad6055', src: '8A1D0D_table_chair' },
      { color: '#da294f', light: '#e56983', src: 'da294f_table_chair' },
      { color: '#4B4453', light: '#817c86', src: '4B4453_table_chair' },
      { color: '#5d5498', light: '#8d87b6', src: '5d5498_table_chair' }
    ]
  },

  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ],

  isPlatinum: false,
}
export default gVar;