<template>
  <v-container fluid class="fill-height">
    <v-row
      class="fill-height"
    >
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
        <AgentCallCard />
      </v-col>
      <v-col>
        <QueueCard />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import AgentCallCard from './components/AgentCallCard.vue';
import QueueCard from './components/QueueCard.vue';
export default {
  name: "MainComponent",
  components: {
    AgentCallCard,
    QueueCard
  }
};
</script>
