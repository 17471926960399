var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"95%","width":"95%"}},[_c('v-container',{staticClass:"d-flex justify-center"},[_c('v-img',{staticClass:"logoAnimation",attrs:{"src":require("@/assets/login/recover_pass.png"),"max-height":"100","contain":""},on:{"load":function($event){_vm.logoIsLoaded = true}}}),(!_vm.logoIsLoaded)?_c('v-layout',{staticStyle:{"width":"100%"},attrs:{"align-center":"","justify-center":""}},[_c('v-skeleton-loader',{attrs:{"height":"150","width":"150","type":"card"}})],1):_vm._e()],1),_c('v-col',{staticClass:"px-12",attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-container',{staticClass:"text-left"},[_c('div',{staticClass:"title-text-div pt-2 mb-6"},[_c('pre',[_vm._v("        "),_c('p',{staticClass:"title-text",style:(_vm.gFunc.fonts.poppins(20, '#47bcc3', '600', ''))},[_vm._v("\n          Quase lá,\n        ")]),_vm._v("\n      ")]),_c('p',{staticClass:"mt-1",style:(_vm.gFunc.fonts.poppins(16, '#888', '400', ''))},[_vm._v(" defina uma nova senha de acesso. ")])]),_c('v-form',{ref:"form"},[_c('TextFieldComValidacao',{ref:"password",attrs:{"label":"Senha","rules":[
          _vm.gFunc.rules.required,
          _vm.rules.min,
          _vm.gFunc.rules.validatePassword,
        ],"password":"","placeholder":"••••••••••••"}}),_c('TextFieldComValidacao',{ref:"confirmPassword",attrs:{"label":"Confirme à Senha","rules":[
          _vm.gFunc.rules.required,
          _vm.rules.min,
          _vm.rules.equal,
          _vm.gFunc.rules.validatePassword,
        ],"password":"","placeholder":"••••••••••••"}}),_c('div',{staticClass:"mb-4"},[(_vm.error)?_c('span',{staticStyle:{"color":"#fe5050"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()]),_c('v-layout',{attrs:{"justify-center":""}},[_c('v-btn',{staticClass:"white--text text-capitalize font-weight-bold btn",attrs:{"loading":_vm.loading,"disabled":_vm.loading,"color":"#47bcc3","rounded":"","height":"40","width":"220"},on:{"click":function($event){return _vm.sendChangePassword()}}},[_c('span',{style:(_vm.gFunc.fonts.poppins(16, 'white'))},[_vm._v(" Alterar senha ")]),_c('v-icon',{staticClass:"pl-2",attrs:{"color":"white"}},[_vm._v(" check ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }