<template>
  <ApplicantCard
    :title="'Detalhes do solicitante'"
    :hasOptions="false"
    :options="[
      {
        icon: 'person_add',
        title: 'Criar parceiro',
      },
    ]"
    :cardHeight="40"
    :diffHeight="62.5"
  >
    <v-card-text
      :class="{
        'py-2': true,
        'px-0': $vuetify.breakpoint.mdAndDown,
      }"
    >
      <v-container
        :class="{
          'px-10': $vuetify.breakpoint.lgAndUp,
          'px-0': $vuetify.breakpoint.mdAndDown,
          'pt-0': true,
        }"
      >
        <v-row no-gutters class="mt-3">
          <!-- <v-col cols="5" class="mt-3 pl-10">
            <span
              class="body-2"
            >
              Nome
            </span>
          </v-col>
          <v-col cols="7" class="d-flex align-center">
            <v-text-field
              dense
              outlined
              height="20"
              :color="Boolean(banner) ? banner['page_text_color'] : '#1d1d1d'"
              :background-color="Boolean(banner) ? banner['page_text_color'] : 'white'"
            />
          </v-col> -->
          <v-col
            cols="5"
            :class="{
              'mt-3': true,
              'pl-10': $vuetify.breakpoint.lgAndUp,
            }"
          >
            <span class="text-caption"> Telefone </span>
          </v-col>
          <v-col cols="7" class="d-flex align-center">
            <v-text-field
              dense
              outlined
              disabled
              height="20"
              hide-details
              :color="Boolean(banner) ? banner['page_text_color'] : '#1d1d1d'"
              :background-color="
                Boolean(banner) ? banner['page_text_color'] : 'white'
              "
            />
            <v-sheet height="20" rounded="md"> </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </ApplicantCard>
</template>
<script>
import { mapGetters } from "vuex";
import ApplicantCard from "../Global/ApplicantCard.vue";
export default {
  name: "MainComponent",
  components: {
    ApplicantCard,
  },
  computed: {
    ...mapGetters({
      banner: "Panel/getBanner",
      agents: "Ws/getAgents",
      userAgentId: "User/getUserAgentId",
      queueSelected: "Ws/getQueueSelected",
      currentChat: "Ws/getCurrentChat",
    }),
    agentChats() {
      if (this.agents.length > 0) {
        const agentIndex = this.agents.findIndex(
          (el) => el.agent.id == this.userAgentId
        );
        if (agentIndex > -1) {
          return this.agents[agentIndex].agent.chats.filter(
            (el) => el.state === "open"
          );
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    currentChat() {
      if (
        this.agentChats.length > 0 &&
        Boolean(this.agentChats[this.queueSelected]) &&
        Boolean(this.currentChat !== null)
      ) {
        return this.agentChats[this.queueSelected].name;
      }
    },
  },
};
</script>
