var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{class:{
    'pa-0': true,
    'pb-3': _vm.$vuetify.breakpoint.mdAndDown,
  },style:(_vm.$vuetify.breakpoint.mdAndDown ? '' : 'height: calc(100vh - 85px)'),attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0 d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn-toggle',{class:{
          'd-flex justify-center': true,
          'flex-column': _vm.$vuetify.breakpoint.lgAndUp,
        },staticStyle:{"background-color":"transparent"},attrs:{"multiple":"","value":_vm.multipleCards},on:{"change":function($event){return _vm.SET_MULTI_PANEL({ data: $event })}}},_vm._l(([
            'mdi-card-account-details-outline',
            'mdi-human-queue',
          ]),function(item,index){return _c('v-btn',{key:index,class:{
            'my-1': _vm.$vuetify.breakpoint.lgAndUp,
            'mx-1': !_vm.$vuetify.breakpoint.lgAndUp,
            'rounded-lg': true,
          },attrs:{"height":"35","width":"35","color":Boolean(_vm.banner) ? _vm.banner['page_bg_color'] : '#C0C0C0'}},[_c('v-icon',{attrs:{"color":Boolean(_vm.banner)
                ? _vm.multipleCards.includes(index)
                  ? _vm.banner['page_text_color']
                  : _vm.banner['page_text_color'] + '8A'
                : 'white'}},[_vm._v(" "+_vm._s(item)+" ")])],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }