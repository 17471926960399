<template>
  <div
    :class="{
      'main d-flex justify-center align-center fill-height': true,
      'px-4': $vuetify.breakpoint.mdAndDown,
    }"
    :style="`
            min-height: 100vh;
            background: ${cardLColor};
            background: -webkit-linear-gradient(bottom right, ${cardLColor}, ${cardRColor});
            background: -moz-linear-gradient(bottom right, ${cardLColor}, ${cardRColor});
            background: linear-gradient(to bottom right, ${cardLColor}, ${cardRColor});`"
  >
    <v-card
      :height="mainCardSize.height"
      :width="mainCardSize.width"
      elevation="12"
      color="white"
      class="mainCard"
    >
      <v-layout class="fill-height" align-center justify-center>
        <v-card
          class="cardLeft"
          :height="$vuetify.breakpoint.lgAndUp ? '640px' : mainCardSize.height"
          :width="$vuetify.breakpoint.lgAndUp ? '55%' : mainCardSize.width"
          color="white"
          elevation="0"
          :style="
            $vuetify.breakpoint.mdAndDown
              ? 'border-radius: 1rem !important;'
              : ''
          "
        >
          <v-layout class="fill-height" column align-center justify-center>
            <CardLogin
              v-if="!showRecoverPass && !showChangePass"
              @clickRecoverPass="showRecoverPass = true"
            />
            <CardRecoverPassword
              v-if="showRecoverPass"
              @goBack="showRecoverPass = false"
            />
            <CardChangePassword v-if="showChangePass" :token="routerHash" />
          </v-layout>
        </v-card>
        <v-card
          v-if="$vuetify.breakpoint.lgAndUp"
          class="cardRight"
          :height="bannerSize.height"
          :width="bannerSize.width"
          elevation="8"
        >
          <v-layout class="fill-height" align-end justify-end>
            <div class="px-2">
              <v-img
                src="@/assets/login/intraflow_small.png"
                contain
                height="150"
                width="170"
                class="intraflowAnimation"
                style="opacity: 0"
              />
            </div>
          </v-layout>
        </v-card>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CardLogin from "./CardLogin.vue";
import CardRecoverPassword from "./CardRecoverPassword.vue";
import CardChangePassword from "./CardChangePassword.vue";
import gFunc from "@/mixins/generic_functions";

export default {
  name: "MainLogin",

  components: {
    CardLogin,
    CardRecoverPassword,
    CardChangePassword,
  },

  data() {
    return {
      gFunc: gFunc,
      show: false,
      showRecoverPass: false,
      showChangePass: false,
      user: "",
      password: "",
      rules: {
        required: (value) => !!value || "Campo Obrigatório.",
        min: (v) => v.length >= 1 || "Mínimo 8 caracteres.",
      },
      loading: false,
      routerHash: "",
      cardLColor: "",
      cardRColor: "",
    };
  },
  methods: {
    ...mapActions({
      getLoginColor: "Login/getLoginColor",
    }),
    ...mapMutations({
      SET_MESSAGE_ERROR: "Error/SET_MESSAGE_ERROR"
    }),
    setCardColor( value ) {
      try {
        if (Boolean(value)) {
          let index = value.indexOf("*")
          this.cardLColor = value.substring(0,index)
          this.cardRColor = value.substring(index + 1, value.length)
        }
      } catch (error) {
        this.SET_MESSAGE_ERROR({
          message: error,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      storeStatus: "Login/getApiStore",
    }),

    mainCardSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return { height: 568, width: 400 };
        case "sm":
          return { height: 550, width: 550 };
        case "md":
          return { height: 600, width: 600 };
        case "lg":
          return { height: 1280 / 2, width: 1200 };
        case "xl":
          return { height: 1000 / 1.4, width: 1500 };
      }
    },

    bannerSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return { height: 1280 / 2, width: 1024 / 1.7 };
        case "xl":
          return { height: 1000 / 1.4, width: 1024 / 1.2 };
      }
    },
  },
  beforeMount() {
    this.setCardColor(localStorage.getItem("login_color"))
    this.routerHash = this.$router.history.current.hash
    if (this.routerHash && this.routerHash.search("token")) {
      this.routerHash = this.routerHash.slice(7, this.routerHash.length)
      this.showChangePass = true
    }
  },
  async mounted() {
    localStorage.removeItem('warningType3')
    let result = await this.getLoginColor()
    this.setCardColor(result)
  },
};
</script>
<style scoped>
.main {
  width: 100%;
}
.mainCard {
  border-radius: 1rem !important;
}
.cardRight {
  background-image: url("../../../assets/login/background.jpg");
  background-position: center;
  background-size: 108%;
  border-radius: 0 !important;
  animation: zoom-out 0.7s ease;
  animation-iteration-count: 1;
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}
.cardLeft {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}
@keyframes zoom-out {
  0% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}
.intraflowAnimation {
  -webkit-animation: intraflow 2s forwards;
  animation: intraflow 2s forwards;
  animation-delay: 0.5s;
}
@keyframes intraflow {
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }

  100% {
    opacity: 70;
    transform: scale(1, 1);
  }
}
</style>
