<template>
  <v-container
    :style="$vuetify.breakpoint.mdAndDown ? '' : 'height: calc(100vh - 85px)'"
    :class="{
      'pa-0': true,
      'pb-3': $vuetify.breakpoint.mdAndDown,
    }"
    fluid
  >
    <v-row no-gutters>
      <v-col cols="12" class="pa-0 d-flex justify-center">
        <v-btn-toggle
          :class="{
            'd-flex justify-center': true,
            'flex-column': $vuetify.breakpoint.lgAndUp,
          }"
          multiple
          :value="multipleCards"
          style="background-color: transparent"
          @change="SET_MULTI_PANEL({ data: $event })"
        >
          <v-btn
            v-for="(item, index) in [
              'mdi-card-account-details-outline',
              'mdi-human-queue',
            ]"
            :key="index"
            :class="{
              'my-1': $vuetify.breakpoint.lgAndUp,
              'mx-1': !$vuetify.breakpoint.lgAndUp,
              'rounded-lg': true,
            }"
            height="35"
            width="35"
            :color="Boolean(banner) ? banner['page_bg_color'] : '#C0C0C0'"
          >
            <v-icon
              :color="
                Boolean(banner)
                  ? multipleCards.includes(index)
                    ? banner['page_text_color']
                    : banner['page_text_color'] + '8A'
                  : 'white'
              "
            >
              {{ item }}
            </v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data: () => ({}),
  methods: {
    ...mapMutations({
      SET_MULTI_PANEL: "Panel/SET_MULTI_PANEL",
    }),
  },
  computed: {
    ...mapGetters({
      banner: "Panel/getBanner",
      multipleCards: "Panel/getMultipleCards",
    }),
  },
};
</script>
