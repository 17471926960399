<template>
  <v-overlay :absolute="absolute" :value="loading" opacity="0.7">
    <div
      class="container"
      :style="`font-size: ${String(fontSize)}px;width: ${String(
        width
      )}px;height: ${String(height)}px;`"
    >
      <span style="--delay: 0s; --color: white">I</span>
      <span style="--delay: 0.1s; --color: white">n</span>
      <span style="--delay: 0.2s; --color: white">t</span>
      <span style="--delay: 0.3s; --color: white">r</span>
      <span style="--delay: 0.4s; --color: white">a</span>
      <span style="--delay: 0.5s; --color: white">F</span>
      <span style="--delay: 0.6s; --color: white">l</span>
      <span style="--delay: 0.7s; --color: white">o</span>
      <span style="--delay: 0.8s; --color: white">w</span>
    </div>
    <v-progress-linear color="blue accent-1" indeterminate rounded height="6" />
  </v-overlay>
</template>

<script>
export default {
  name: "LoadingIntraflow",

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: Number,
      default: 80,
    },
    width: {
      type: Number,
      default: 600,
    },
    height: {
      type: Number,
      default: 200,
    },
  },
};
</script>
<style scoped>
.container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  display:flex;
  align-items: center;
  justify-content: center;
}
.container > span {
  position: relative;
  top: 20px;
  display: inline-block;
  animation: bounce .3s ease infinite alternate;
  font-family: "Impact",sans-serif;
  /* font-size: 80px; */
  text-shadow:  0 1px 0 #CCC,
                0 2px 0 #CCC,
                0 3px 0 #CCC,
                0 4px 0 #CCC,
                0 5px 0 #CCC,
                0 6px 0 transparent,
                0 7px 0 transparent,
                0 8px 0 transparent,
                0 9px 0 transparent,
                0 10px 10px rgba(0, 0, 0, .4);
}
.container > span:nth-child(1n+0) {
  color:var(--color);
  animation-delay: var(--delay);
}
@keyframes bounce
{
  100%
  {
    top: -20px;
    text-shadow: 0 1px 0 #CCC,
                 0 2px 0 #CCC,
                 0 3px 0 #CCC,
                 0 4px 0 #CCC,
                 0 5px 0 #CCC,
                 0 6px 0 #CCC,
                 0 7px 0 #CCC,
                 0 8px 0 #CCC,
                 0 9px 0 #CCC,
                 0 50px 25px rgba(0, 0, 0, .2);
  }
}
</style>