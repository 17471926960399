import axios from "axios";
import store from "@/store";

const initialState = () => ({
  loadingPanel: false,
  banner: null,
  multipleCards: [0, 1]
});
const state = initialState();
const getters = {
  getLoading: (state) => state.loadingPanel,
  getBanner: (state) => state.banner,
  getMultipleCards: (state) => state.multipleCards,
};
const actions = {
  async setBanner(context, { token }) {
    axios
      .get(
        process.env.VUE_APP_BASE_URL + "/iliosrest/get/intranet.dinamic.banner",
        {
          headers: {
            "Access-Token": token,
          },
          params: {
            db: process.env.VUE_APP_DATA_BASE,
            fields:
              "['id', 'icon', 'color', 'banner_title', 'page_bg_color', 'page_text_color']",
            domain: "[('active_banner', '=', True)]",
            fields_to_decode: "['icon']",
            context: {
              lang: "pt_BR",
              tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          },
        }
      )
      .then((response) => {
        if (response["status"] == 200) {
          if (response.data.data[0]) {
            context.commit("SET_BANNER", {
              data: response.data.data[0],
            });
          }
        }
      })
      .catch((e) => {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
  },
};
const mutations = {
  LOAD(state, { load }) {
    state.loadingPanel = load;
  },
  SET_MULTI_PANEL(state, { data }) {
    state.multipleCards = data
  },
  SET_BANNER(state, { data }) {
    state.banner = data
  },
  CLEAN(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};
export default {
  actions,
  state,
  getters,
  mutations,
  namespaced: true,
};
