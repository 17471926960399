<template>
  <v-app>
    <v-main>
      <div
        class="fill-height"
      >
        <Sidebar v-if="atDashboard" />
        <WebSocket />
        <div
          :style="atDashboard ? 'padding-top: 40px; height: 100%; background-color: rgb(248,247,250)' : ''"
        >
          <router-view />
          <Loader :isLoading="routerLoading" :occurredError="false" :opacity="0.1" />
        </div>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Loader from './components/Global/Loader.vue';
import Sidebar from './components/Global/Sidebar.vue';
import WebSocket from './components/Websocket/MainComponent.vue'
export default {
  name: "App",

  components: {
    Sidebar,
    Loader,
    WebSocket
  },

  methods: {
    ...mapActions({
      fetchAccessToken: 'Login/fetchAccessToken',
      getUserById: 'User/getUserById',
      setBanner: "Panel/setBanner",
    }),
    async logout(){
      if(this.$socket) {
        let access_token = localStorage.getItem("accessToken")
        await this.$socket.sendObj({
          db_name: process.env.VUE_APP_DATA_BASE,
          access_token: access_token,
          action: "logout",
        });
      }
    }
  },

  computed: {
    ...mapGetters({
      userId: 'Login/getUserId',
      routerLoading: 'Panel/getLoading',
    }),
    atDashboard(){
      return this.$route.fullPath.split('/')[1] === 'chat'
    }
  },

  mounted(){
    window.addEventListener('beforeunload', this.logout)
  },

  created() {
    this.fetchAccessToken()
    const userToken = localStorage.getItem("accessToken")
    if (userToken != 'false' && userToken != null) {
      this.getUserById({
        token: userToken,
        domain: "[('id', '=', " + this.userId + ")]"
      })
      this.setBanner({
        token: userToken
      })
    }
  },

  beforeDestroy(){
    window.removeEventListener('beforeunload', this.logout)
  },
};
</script>
