/* Module1.store.js */

import axios from "axios";
import iliosState from "@/ilios.store";
import store from "@/store";

const initialState = () => ({
  loginToken: "",
  loggedIn: false,
  userId: null,
  inVacation: false,
  apiStore: iliosState.INIT,
});

// State object
const state = initialState();

// Getter functions
const getters = {
  getToken(state) {
    return state.loginToken;
  },

  getloggedIn(state) {
    return state.loggedIn;
  },

  getUserId(state) {
    return state.userId;
  },

  getinVacation(state) {
    return state.inVacation;
  },

  getApiStore(state) {
    return state.apiStore;
  },
};

// Actions
const actions = {
  fetchAccessToken(context) {
    context.commit("SIGN_IN", {
      token: localStorage.getItem("accessToken"),
      userId: localStorage.getItem("userId"),
    });
  },

  async signIn(context, { user, password }) {
    let result = "";
    context.commit("SET_STORE_STATE", {
      storeState: iliosState.LOADING,
    });

    const url = process.env.VUE_APP_BASE_URL + "/iliosrest/auth/token";

    const data = {
      data: {
        login: String(user).toLowerCase().trim(),
        password: password,
        db: process.env.VUE_APP_DATA_BASE,
      },
    };

    const config = {
      headers: {
        "Content-Type": "form/x-www-form-urlencoded",
        charset: "utf-8",
      },
    };

    await axios
      .post(url, data, config)
      .then((response) => {
        if (response["status"] == 200) {
          localStorage.setItem("accessToken", response["data"]["access_token"]);
          localStorage.setItem("userId", response["data"]["uid"]);
          context.commit("SIGN_IN", {
            token: response["data"]["access_token"],
            userId: response["data"]["uid"],
          });
          context.commit("SETLOGGEDIN", { isLogged: true });
          context.commit("SET_STORE_STATE", {
            storeState: iliosState.LOADED,
          });
          store.dispatch("Panel/setBanner", {
            token: response["data"]["access_token"],
          });
          result = true;
        }
      })
      .catch((e) => {
        if (e.response) {
          result = "Falha no login, verifique suas credênciais.";
        } else {
          result = "Não foi possível se conectar com o servidor.";
        }
        context.commit("SET_STORE_STATE", {
          storeState: iliosState.ERROR,
        });
      });
    return result;
  },

  async postRecoverPassword(context, { email }) {
    let result = "";
    context.commit("SET_STORE_STATE", {
      storeState: iliosState.LOADING,
    });

    const url = process.env.VUE_APP_BASE_URL + "/intranet/recover_password";

    const data = {
      data: {
        login: String(email).toLowerCase().trim(),
        db: process.env.VUE_APP_DATA_BASE,
      },
    };

    const config = {
      headers: {
        "Content-Type": "form/x-www-form-urlencoded",
        charset: "utf-8",
      },
    };

    await axios
      .post(url, data, config)
      .then((response) => {
        if (response["status"] == 200) {
          result = response["data"];
          context.commit("SET_STORE_STATE", {
            storeState: iliosState.LOADED,
          });
        }
      })
      .catch((e) => {
        if (e.response) {
          let objError = e.response["data"];
          if (objError.message.search("invalid username or email")) {
            objError.message =
              "O email inserido, não está registrado na Intraflow.";
          }
          result = objError;
        } else {
          result = { message: "Não foi possível se conectar com o servidor." };
        }
        context.commit("SET_STORE_STATE", {
          storeState: iliosState.LOADED,
        });
      });
    return result;
  },

  async postChangePassword(context, { token, password, confirm_password }) {
    let result = "";
    context.commit("SET_STORE_STATE", {
      storeState: iliosState.LOADING,
    });

    const url = process.env.VUE_APP_BASE_URL + "/intranet/recover_password";

    const data = {
      data: {
        token: token,
        password: password,
        confirm_password: confirm_password,
        db: process.env.VUE_APP_DATA_BASE,
      },
    };

    const config = {
      headers: {
        "Content-Type": "form/x-www-form-urlencoded",
        charset: "utf-8",
      },
    };

    await axios
      .post(url, data, config)
      .then((response) => {
        if (response["status"] == 200) {
          result = response["data"];
          context.commit("SET_STORE_STATE", {
            storeState: iliosState.LOADED,
          });
        }
      })
      .catch((e) => {
        if (e.response) {
          let objError = e.response["data"];
          result = objError;
        } else {
          result = { message: "Não foi possível se conectar com o servidor." };
        }
        context.commit("SET_STORE_STATE", {
          storeState: iliosState.LOADED,
        });
      });
    return result;
  },

  async getInVacation(context) {
    var UserInVacation = "";
    await axios
      .get(process.env.VUE_APP_BASE_URL + "/iliosrest/auth/vacation", {
        params: {
          db: process.env.VUE_APP_DATA_BASE,
          context: {
            lang: "pt_BR",
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
        headers: {
          "Content-Type": "form/x-www-form-urlencoded",
          charset: "utf-8",
          "Access-Token": context.getters.getToken,
        },
      })
      .then((response) => {
        if (response["status"] == 200) {
          context.commit("SET_INVACATION", {
            inVacation: response.data.return,
          });
        }
        if (context.getters.getToken) {
          if (!response.data.return) {
            context.commit("SETLOGGEDIN", { isLogged: true });
            UserInVacation = false;
          } else {
            UserInVacation = true;
          }
        }
      })
      .catch((e) => {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
    return UserInVacation;
  },

  async getLoginColor() {
    let result = null;

    await axios
      .get(process.env.VUE_APP_BASE_URL + "/intranet/get/login_color", {
        params: {},
        headers: {
          "Content-Type": "form/x-www-form-urlencoded",
          charset: "utf-8",
        },
      })
      .then((response) => {
        if (response["status"] == 200) {
          result = response.data.data.color;
          localStorage.setItem("login_color", response.data.data.color);
        }
      })
      .catch((e) => {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
    return result;
  },

  async putLoginLayout(context, { payload, token }) {
    var result = null;

    let config = {
      headers: {
        "Content-Type": "form/x-www-form-urlencoded",
        Accept: "application/json",
        charset: "utf-8",
        "Access-Token": token,
      },
    };

    let data = {
      data: payload,
    };

    let url = process.env.VUE_APP_BASE_URL + "/intranet/put/login_layout";

    await axios
      .put(url, (data = data), config)
      .then((response) => {
        if (response["status"] == 200) {
          result = true;
        }
      })
      .catch((e) => {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
    return result;
  },

  UserLogOut(context) {
    context.commit("SETLOGGEDIN", { isLogged: false });
    context.commit("RESET");
    localStorage.clear()
  },
};

// Mutations
const mutations = {
  SIGN_IN(state, { token, userId }) {
    state.loginToken = token;
    state.userId = userId;
  },

  SETLOGGEDIN(state, { isLogged }) {
    state.loggedIn = isLogged;
  },

  SET_INVACATION(state, inVacation) {
    state.inVacation = inVacation;
  },

  SET_STORE_STATE(state, { storeState }) {
    state.apiStore = storeState;
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  actions,
  state,
  getters,
  mutations,
  namespaced: true,
};
