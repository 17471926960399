<template>
  <v-sheet class="mx-auto" @keyup.esc="drawer = false">
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      right
      width="350"
      @transitionend="setFocus()"
    >
      <v-list-item style="bgListItem">
        <v-btn fab x-small text @click="close()">
          <v-icon> close </v-icon>
        </v-btn>
        <v-list-item-content class="px-2">
          <v-list-item-title> Pesquisar Mensagens </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item class="white">
        <v-list-item-content>
          <v-text-field
            ref="textFieldSearch"
            v-model="searchText"
            clearable
            flat
            dense
            hide-details
            prepend-inner-icon="search"
            outlined
            color="grey"
            background-color="#f0f2f5"
            placeholder="Pesquisar..."
          />
        </v-list-item-content>
      </v-list-item>
      <v-list dense>
        <v-divider />
        <div v-for="(item, key) in messages" :key="key">
          <div :key="key">
            <v-list-item link class="pb-3" @click="getSearchMessage(item)">
              <div class="text-truncate" style="w320">
                <span style="poppins11">
                  {{ formatDateToScreen(item.create_date) }}
                </span>
                <br />
                <span
                  style="poppins13"
                  v-html="senderName(item) + ': ' + item.message"
                />
              </div>
            </v-list-item>
            <v-divider />
          </div>
        </div>
      </v-list>
      <v-layout v-show="awaitingSearch" justify-center align-center>
        <div class="d-flex justify-center align-center" :style="progressBox">
          <v-progress-circular :size="50" color="blue" indeterminate />
        </div>
      </v-layout>
      <v-layout
        v-show="
          !awaitingSearch && (!this.searchText || this.searchText.length < 3)
        "
        class="pt-5"
        align-center
        justify-center
      >
        <div class="text-truncate text-center" :style="w300">
          <span :style="poppins14">
            Pesquisar mensagens com {{ userFirstName }}
          </span>
        </div>
      </v-layout>
      <v-layout
        v-show="
          !awaitingSearch &&
          messages.length === 0 &&
          searchText &&
          searchText.length > 2
        "
        align-center
        justify-center
        class="pt-5"
      >
        <div class="text-center" :style="w300">
          <span style="poppins14">
            Não foi encontrado nenhuma mensagem com a palavra "
            <b>
              {{ searchText }} </b
            >"
          </span>
        </div>
      </v-layout>
    </v-navigation-drawer>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "searchMessage",

  props: {
    chatUser: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      drawer: false,
      awaitingSearch: false,
      searchText: null,
      messages: [],
    };
  },

  computed: {
    ...mapGetters({
      userToken: "Login/getToken",
      userId: "User/getUserId",
      chatStoreState: "Chat/getApiState",
    }),

    userFirstName() {
      return this.chatUser ? this.chatUser.name.split(" ")[0] : "";
    },

    bgListItem: () => "background-color: #f0f2f5;",
    w300: () => "width: 300px;",
    w320: () => "width: 320px;",
    poppins11: () =>
      "font-family: 'Poppins', sans-serif;\
                      font-size: 11.0px;\
                      color:grey;",
    poppins13: () =>
      "font-family: 'Poppins', sans-serif;\
                      font-size: 13.0px;\
                      color:grey;",
    poppins14: () =>
      "font-family: 'Poppins', sans-serif;\
                      font-size: 14.0px;\
                      color:grey;",
    progressBox: () => "border-radius: 100%; height: 80px; 80px",
  },

  watch: {
    searchText: function () {
      this.messages = [];
      if (this.searchText) {
        if (this.searchText.length > 2 && !this.awaitingSearch) {
          this.awaitingSearch = true;
          setTimeout(() => {
            this.showSearchMessages();
          }, 1500);
        }
      }
    },

    chatStoreState: function () {
      if (this.chatStoreState === 3) {
        this.awaitingSearch = false;
      }
    },
  },

  methods: {
    ...mapActions({
      getSimpleMessages: "Chat/getSimpleMessages",
    }),

    setFocus() {
      this.$refs.textFieldSearch.focus();
    },

    getSearchMessage(item) {
      this.$emit("clickMessage", item);
      this.close();
    },

    cleanForm() {
      this.awaitingSearch = false;
      this.searchText = null;
      this.messages = [];
    },

    close() {
      this.drawer = false;
    },

    senderName(item) {
      if (item.sender_user_id[0] === this.userId) {
        return "Você";
      } else {
        return item.sender_user_id[1].split(" ")[0];
      }
    },

    async showSearchMessages() {
      if (this.searchText && this.searchText.length > 2) {
        let resultMessages = await this.getSimpleMessages({
          token: this.userToken,
          domain: `['&', '|', ('message', 'like', '${this.searchText}'), ('sender_user_id', '=', ${this.chatUser.id}), ('receiver_user_id', '=', ${this.chatUser.id})]`,
        });
        if (resultMessages) {
          resultMessages.forEach((el) => {
            let indexSearch = el.message.search(this.searchText);
            if (indexSearch > 15 && el.message.length > 30) {
              el.message =
                "<b>...</b>" +
                el.message
                  .replace(this.searchText, "<b>" + this.searchText + "</b>")
                  .slice(indexSearch - 10);
            } else {
              el.message = el.message.replace(
                this.searchText,
                "<b>" + this.searchText + "</b>"
              );
            }
          });
          this.messages = resultMessages;
        }
      } else {
        this.awaitingSearch = false;
      }
    },

    formatDateToScreen(date) {
      let actualDate = new Date();
      let newDate = new Date(date);
      let formatedDate = new Date(
        newDate.valueOf() - newDate.getTimezoneOffset() * 60000
      );
      formatedDate = moment(formatedDate).locale("pt-br").format("DD/MM/YYYY");
      actualDate = moment(actualDate).locale("pt-br").format("DD/MM/YYYY");
      if (actualDate === formatedDate) {
        return "Hoje";
      } else {
        return formatedDate;
      }
    },
  },
};
</script>
