<template>
  <ApplicantCard
    :title="'Tickets'"
    :hasOptions="false"
    :cardHeight="70"
    :cardTextClass="'pr-0'"
  >
    <v-container style="overflow: auto; height: 35vh">
      <v-expansion-panels
        focusable
      >
        <v-expansion-panel
          class="elevation-0"
          v-for="(item, i) in new Array(5)"
          :key="i"
        >
          <v-expansion-panel-header
            class="py-1"
          >
            <v-layout justify-space-between align-center>
              <span> Header </span>
              <v-menu offset-y rounded="lg">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon> more_vert </v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <div
                    v-for="(item, index) in [
                      {
                        icon: 'picture_as_pdf',
                        title: 'Salvar em PDF',
                      },
                    ]"
                    :key="index"
                  >
                    <v-list-item link class="py-0">
                      <v-list-item-icon>
                        <v-icon>
                          {{ item.icon }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="text-caption">
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </div>
                </v-list>
              </v-menu>
            </v-layout>
          </v-expansion-panel-header>
          <v-expansion-panel-content> Content </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </ApplicantCard>
</template>
<script>
import ApplicantCard from "../Global/ApplicantCard.vue";
export default {
  name: "MainComponent",
  components: {
    ApplicantCard,
  },
};
</script>
