var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-column justify-end fill-height",staticStyle:{"width":"100%"}},[_c('p',{staticClass:"text-h4"},[_vm._v("Canais")]),_c('v-card',{staticClass:"mb-4"},[_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"no-results-text":"Não há registro.","no-data-text":"Não há registro.","loading-text":"Carregando canais...","loading":_vm.loadingChannels,"expanded":_vm.expanded,"items-per-page":10,"hide-default-footer":"","items":_vm.channels,"page":_vm.page,"single-expand":"","item-key":"channel_id","show-expand":"","headers":[
          {
            text: 'Canal',
            value: 'name',
          },
          {
            text: '',
            value: 'data-table-expand',
          },
        ]},on:{"update:expanded":function($event){_vm.expanded=$event},"update:page":function($event){_vm.page=$event},"click:row":_vm.toggleRow},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('td',{directives:[{name:"show",rawName:"v-show",value:(item),expression:"item"}]},[_c('p',{staticClass:"mb-0 pb-0"},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.setChannelOrigin(item.channel_origin))+" ")]),_vm._v(" - "+_vm._s(item.name)+" ")],1)])]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{directives:[{name:"show",rawName:"v-show",value:(item),expression:"item"}],staticClass:"text-center mt-2",staticStyle:{"width":"100vw"},attrs:{"colspan":headers.length}},[(!item.is_ready)?_c('div',{staticClass:"d-flex justify-center mt-3 flex-column align-center"},[(item.qr_code)?_c('v-img',{attrs:{"width":"350px","src":item.qr_code}}):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.qrCodeCountdown)+" ")])],1):_c('span',[_vm._v(" Qr Code lido ")])])]}}],null,true)})],1),(_vm.pageCount && _vm.pageCount > 0)?_c('v-pagination',{staticClass:"mt-auto",attrs:{"color":Boolean(_vm.banner) ? _vm.banner['page_bg_color'] : 'primary',"length":_vm.pageCount && _vm.pageCount > 0 ? _vm.pageCount : 0},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_c('div',{staticClass:"mt-auto"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }