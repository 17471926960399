<template>
  <label class="black--text">
    <span
      :style="gFunc.fonts.poppins(labelFontSize, '#1D1D1D', '600')"
      v-text="label"
    />
    <v-text-field
      :style="gFunc.fonts.poppins(fontSize, '#1D1D1D', '400')"
      ref="textField"
      v-model="text"
      v-mask="mask"
      :disabled="disabled"
      outlined
      :placeholder="placeholder"
      :hide-details="hide_details"
      :prefix="prefix"
      class="mt-1 text-field-com-validacao"
      :class="getValidationStatus"
      :rules="rules"
      @input="onInput"
      @change="$emit('change', text)"
      @focus="isOnFocus = true"
      @blur="isOnFocus = false"
      :type="hiddenPassword ? 'password' : 'text'"
    >
      <template v-slot:append>
        <v-icon
          v-if="!password && validationStatus"
          @click="textFieldIconClick"
          :style="validationStatus !== '_error' ? 'cursor: default' : ''"
          :tabindex="validationStatus !== '_error' ? '-1' : ''"
          :class="getValidationStatus"
        >
          {{ validationStatusIcons[validationStatus] }}</v-icon
        >

        <v-btn
          icon
          class="mt-n1"
          :color="'grey'"
          v-if="password"
          @click="showPassword = !showPassword"
        >
          <v-icon color="grey">{{
            hiddenPassword ? "visibility" : "visibility_off"
          }}</v-icon>
        </v-btn>
      </template>

      <template v-slot:prepend-inner>
        <v-icon :color="isOnFocus ? '#F15A22' : ''" class="prepend-icon">{{
          prependInnerIcon
        }}</v-icon>
      </template>
    </v-text-field>
  </label>
</template>

<script>
import gFunc from "@/mixins/generic_functions.js";

export default {
  name: "TextFieldComValidacao",
  props: {
    value: String,
    label: String,
    placeholder: String,
    disabled: Boolean,
    password: Boolean,
    hide_details: Boolean,
    prefix: String,
    rules: Array,
    mask: String,
    prependInnerIcon: String,
    fontSize: {
      type: Number,
      default: 12,
    },
    labelFontSize: {
      type: Number,
      default: 12,
    },

    watchValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      gFunc: gFunc,
      validationStatus: "",
      text: this.value,
      isOnFocus: false,
      showPassword: false,
      validationStatusIcons: {
        _success: "mdi-check",
        _warning: "mdi-alert-circle-outline",
        _error: "mdi-close",
      },
    };
  },

  watch: {
    value: function (newValue, olValue) {
      if (this.watchValue) {
        this.text = newValue;
      }
    },
  },

  computed: {
    getValidationStatus() {
      return this.validationStatus;
    },

    hiddenPassword() {
      return !this.showPassword && this.password;
    },
  },

  mounted() {
    this.$watch(
      () => {
        return this.$refs.textField.valid;
      },
      (val) => {
        this.changeValidationStatus();
      }
    );

    this.$emit("mounted");
  },

  methods: {
    changeValidationStatus() {
      if (this.rules && this.$refs.textField.valid) {
        this.validationStatus = "_success";
      } else if (this.rules) {
        this.validationStatus = "_error";
      }

      this.$emit("validationStatusChange");
    },

    textFieldIconClick() {
      if (this.validationStatus === "_error") {
        this.validationStatus = "";
        this.text = "";
      }
    },

    emitChange() {
      this.$emit("input", this.text);
    },

    onInput() {
      this.emitChange();
      this.changeValidationStatus();
    },

    resetTextFieldValidade() {
      this.$refs.textField.resetValidation();
    },
  },
};
</script>

<style>
.text-field-com-validacao .v-input__append-inner {
  padding: 0 !important;
}

.v-text-field__prefix {
  margin-right: 15px;
  color: #000;
}

.v-text-field__slot {
  padding-left: 0.4rem;
}

.v-input fieldset {
  border-color: #ccc !important;
  transition: none !important;
}

.v-input--is-disabled fieldset {
  color: #ccc;
  background: #eee !important;
}

.v-input:hover fieldset,
.v-input:focus-within fieldset {
  border-width: 2px !important;
  border-color: #47bcc3 !important;
  -webkit-box-shadow: 0px 0px 6px -3px #47bcc3;
  box-shadow: 0px 0px 6px -3px #47bcc3;
}

/* _success */
._success {
  color: #4c4 !important;
}

._success fieldset {
  border-color: #4c4 !important;
  background-color: #efe;
}

._success:hover fieldset,
._success:focus-within fieldset {
  border-width: 2px !important;
  border-color: #4c4 !important;
  -webkit-box-shadow: 0px 0px 6px -3px #4c4;
  box-shadow: 0px 0px 6px -3px #4c4;
}

/* _warning */
._warning {
  color: #dd1 !important;
}

._warning fieldset {
  border-color: #dd1 !important;
  background-color: #ffe;
}

._warning:hover fieldset,
._warning:focus-within fieldset {
  border-width: 2px !important;
  border-color: #dd1 !important;
  -webkit-box-shadow: 0px 0px 6px -3px #dd1;
  box-shadow: 0px 0px 6px -3px #dd1;
}

/* _error */
._error {
  color: #e44 !important;
}

._error fieldset {
  border-color: #e44 !important;
  background-color: #fee;
}

._error:hover fieldset,
._error:focus-within fieldset {
  border-width: 2px !important;
  border-color: #e44 !important;
  -webkit-box-shadow: 0px 0px 6px -3px #e44;
  box-shadow: 0px 0px 6px -3px #e44;
}

.v-text-field__details {
  margin-top: 5px !important;
  padding-left: 0 !important;
}

.v-messages__message {
  font-size: 0.8rem !important;
  color: red !important;
}
</style>
