<template>
  <div style="height: 95%; width: 95%">
    <v-container class="d-flex justify-center">
      <v-img
        src="@/assets/login/recover_pass.png"
        max-height="100"
        contain
        @load="logoIsLoaded = true"
        class="logoAnimation"
      />
      <v-layout
        v-if="!logoIsLoaded"
        style="width: 100%"
        align-center
        justify-center
      >
        <v-skeleton-loader height="150" width="150" type="card" />
      </v-layout>
    </v-container>

    <v-col class="px-12" cols="12">
      <v-divider />
    </v-col>

    <v-container v-if="showSendSucess" style="height: 70%">
      <v-layout class="fill-height" justify-center align-end>
        <div class="letter-image">
          <div class="animated-mail">
            <div class="back-fold"></div>
            <div class="letter">
              <div class="letter-border"></div>
              <div class="letter-title"></div>
              <div class="letter-context"></div>
              <div class="letter-stamp">
                <div class="letter-stamp-inner"></div>
              </div>
            </div>
            <div class="top-fold"></div>
            <div class="body"></div>
            <div class="left-fold"></div>
          </div>
          <div class="shadow"></div>
        </div>

        <div
          class="pt-2 mb-6 sendMessage"
          :style="`width: ${$vuetify.breakpoint.lgAndUp ? '30' : '50'}vh;`"
        >
          <p
            :style="gFunc.fonts.poppins(12, 'white', '500', '')"
            class="px-4 pt-1"
          >
            Um email foi enviado com as credenciais para resetar sua senha
          </p>
        </div>
      </v-layout>
    </v-container>

    <v-container v-if="!showSendSucess" class="text-left">
      <div class="title-text-div pt-2 mb-6">
        <pre v-if="!gFunc.isMobile()">
          <p
            class="title-text"
            :style="gFunc.fonts.poppins(20, '#47bcc3', '600', '')"
          >
            Fique tranquilo,
          </p>
        </pre>

        <p
          v-if="!gFunc.isMobile()"
          :style="gFunc.fonts.poppins(16, '#888', '400', '')"
          class="mt-1"
        >
          vamos lhe ajudar a recuperar sua senha
        </p>
      </div>

      <v-form ref="form">
        <TextFieldComValidacao
          :rules="[gFunc.rules.required, gFunc.rules.validateEmail]"
          label="Seu Email"
          placeholder="exemplo@email.com.br"
          ref="email"
        />

        <div class="mb-4">
          <span v-if="error" style="color: #fe5050">
            {{ error }}
          </span>
        </div>

        <v-layout justify-space-between>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="goBack()"
                color="#47bcc3"
                fab
                class="btn"
                height="40"
                width="40"
                elevation="1"
                :disabled="loading"
              >
                <v-icon color="white"> arrow_left </v-icon>
              </v-btn>
            </template>
            <span> Voltar </span>
          </v-tooltip>

          <v-btn
            @click="sendRecover()"
            :loading="loading"
            :disabled="loading"
            color="#47bcc3"
            rounded
            class="white--text text-capitalize font-weight-bold btn"
            height="40"
            width="220"
          >
            <span :style="gFunc.fonts.poppins(16, 'white')">
              recuperar senha
            </span>
            <v-icon color="white" class="pl-2"> arrow_right </v-icon>
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import gFunc from "@/mixins/generic_functions";
import TextFieldComValidacao from "@/components/Global/TextFieldComValidacao";

export default {
  name: "CardLogin",

  components: {
    TextFieldComValidacao,
  },

  data() {
    return {
      gFunc: gFunc,
      logoIsLoaded: false,
      loading: false,
      error: "",
      showSendSucess: false,
    };
  },

  methods: {
    ...mapActions({
      postRecover: "Login/postRecoverPassword",
    }),

    goBack() {
      this.$emit("goBack")
    },

    ...mapGetters({}),

    async sendRecover() {
      if (!this.loading && this.$refs.form.validate()) {
        this.loading = true;
        this.error = "";
        let email = this.$refs.email.text;
        let result = await this.postRecover({
          email: email,
        });
        if (result.retorno === true) {
          this.showSendSucess = true;
        } else {
          this.error = result.message;
        }
      }
      this.loading = false;
    },
  }
};
</script>
<style scoped>
.sendMessage {
  border-radius: 15px;
  background-color: rgba(40, 167, 69, 0.8);
}

.logoAnimation {
  animation: zoom-in 0.5s ease;
  animation-iteration-count: 1;
}

@keyframes zoom-in {
  0% {
    transform: scale(0.2, 0.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

.btn:hover {
  -webkit-animation: iconAnimation 0.3s forwards; /* for less modern browsers */
  animation: iconAnimation 0.3s forwards;
}

@keyframes iconAnimation {
  0% {
    transform: scale(1, 1);
    background-color: #47bcc3;
  }
  100% {
    transform: scale(1.1, 1.1);
    background-color: #086e72;
  }
}

/* Criação da carta e animação */
body {
  background: #323641;
}

.letter-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.animated-mail {
  position: absolute;
  height: 150px;
  width: 200px;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}

.body {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 100px 200px;
  border-color: transparent transparent #e95f55 transparent;
  z-index: 2;
}

.top-fold {
  position: absolute;
  top: 50px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 100px 0 100px;
  -webkit-transform-origin: 50% 0%;
  -webkit-transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
  -moz-transform-origin: 50% 0%;
  -moz-transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
  transform-origin: 50% 0%;
  transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
  border-color: #cf4a43 transparent transparent transparent;
  z-index: 2;
}

.back-fold {
  position: absolute;
  bottom: 0;
  width: 200px;
  height: 100px;
  background: #cf4a43;
  z-index: 0;
}

.left-fold {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 0 50px 100px;
  border-color: transparent transparent transparent #e15349;
  z-index: 2;
}

.letter {
  left: 20px;
  bottom: 0px;
  position: absolute;
  width: 160px;
  height: 60px;
  background: white;
  z-index: 1;
  overflow: hidden;
  -webkit-transition: 0.4s 0.2s;
  -moz-transition: 0.4s 0.2s;
  transition: 0.4s 0.2s;
}

.letter-border {
  height: 10px;
  width: 100%;
  background: repeating-linear-gradient(
    -45deg,
    #cb5a5e,
    #cb5a5e 8px,
    transparent 8px,
    transparent 18px
  );
}

.letter-title {
  margin-top: 10px;
  margin-left: 5px;
  height: 10px;
  width: 40%;
  background: #cb5a5e;
}

.letter-context {
  margin-top: 10px;
  margin-left: 5px;
  height: 10px;
  width: 20%;
  background: #cb5a5e;
}

.letter-stamp {
  margin-top: 30px;
  margin-left: 120px;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  background: #cb5a5e;
  opacity: 0.3;
}

.shadow {
  position: absolute;
  top: 200px;
  left: 50%;
  width: 400px;
  height: 30px;
  transition: 0.4s;
  transform: translateX(-50%);
  -webkit-transition: 0.4s;
  -webkit-transform: translateX(-50%);
  -moz-transition: 0.4s;
  -moz-transform: translateX(-50%);

  border-radius: 100%;
  background: radial-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}

.animated-mail {
  -webkit-animation: animatedMail 1s forwards; /* for less modern browsers */
  animation: animatedMail 1s forwards;
}

@keyframes animatedMail {
  100% {
    transform: translateY(50px);
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
  }
}

.animated-mail .top-fold {
  -webkit-animation: animatedMailTopFold 1s forwards;
  animation: animatedMailTopFold 1s forwards;
}

@keyframes animatedMailTopFold {
  100% {
    transition: transform 0.4s, z-index 0.2s;
    transform: rotateX(180deg);
    -webkit-transition: transform 0.4s, z-index 0.2s;
    -webkit-transform: rotateX(180deg);
    -moz-transition: transform 0.4s, z-index 0.2s;
    -moz-transform: rotateX(180deg);
    z-index: 0;
  }
}

.animated-mail .letter {
  -webkit-animation: animatedMailLetter 2.1s forwards;
  animation: animatedMailLetter 2.1s forwards;
}

@keyframes animatedMailLetter {
  100% {
    height: 180px;
  }
}

.shadow {
  width: 250px;
}
</style>
