import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/vueNativeWebsocket";
import "./plugins/vueMask"
import gVar from "./mixins/global_variables"
import gFunc from "./mixins/generic_functions";

Vue.config.productionTip = false;
Vue.prototype.$gVar = gVar;
Vue.prototype.$gFunc = gFunc;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
