<template>
  <div>
    <v-progress-circular
      indeterminate
      :width="9"
      v-if="!qrcode || qrcode === null || qrcode === '' || qrCodeLoading"
      size="200"
    />
    <QrcodeVue
      :value="qrcode && qrcode.length > 0 ? qrcode : ''"
      size="264"
      v-else
    />
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { mapGetters } from 'vuex';
export default {
  name: "QrCode",
  components: {
    QrcodeVue
  },
  props: {
    qrcode: null
  },
  computed: {
    ...mapGetters({
      qrCodeLoading: "ChatApi/getQrCodeLoading"
    })
  }

};
</script>