<template>
  <div style="height: 95%; width: 95%">
    <v-container class="d-flex justify-center">
      <v-img
         :src="`${urlIcon}/web/image?model=res.company&id=1&field=logo`"
        :max-height="$vuetify.breakpoint.smAndDown ? '110px' : '150px'"
        contain
        @load="logoIsLoaded = true"
        class="logoAnimation"
      />
      <v-layout
        v-if="!logoIsLoaded"
        style="width: 100%"
        align-center
        justify-center
      >
        <v-skeleton-loader height="150" width="150" type="card" />
      </v-layout>
    </v-container>
    <v-col class="px-12" cols="12">
      <v-divider />
    </v-col>
    <v-container class="text-left">
      <v-form ref="form" validate-on="submit lazy" @submit.prevent="onSignin">
        <TextFieldComValidacao
          :rules="[gFunc.rules.required]"
          label="Email"
          placeholder="exemplo@email.com.br"
          ref="email"
        />

        <TextFieldComValidacao
          label="Senha"
          :rules="[gFunc.rules.required]"
          password
          placeholder="••••••••••••"
          ref="password"
        />

        <div class="mb-4">
          <span v-if="loginStatus !== true" style="color: #fe5050">
            {{ loginStatus }}
          </span>
        </div>

        <v-layout justify-center>
          <v-btn
            type="submit"
            :loading="loading"
            :disabled="loading"
            color="#47bcc3"
            rounded
            class="white--text text-capitalize font-weight-bold btnSignin"
            height="40"
            width="130"
          >
            <span :style="gFunc.fonts.poppins(16, 'white')"> entrar </span>
            <v-icon color="white" class="pl-2"> exit_to_app </v-icon>
          </v-btn>
        </v-layout>
      </v-form>
      <div class="login-card-footer text-right mt-5 mt-md-10">
        <p :style="gFunc.fonts.poppins(12, '#686872', '400', '')">
          Esqueceu sua senha?
          <a
            @click="clickRecoverPass()"
            :style="gFunc.fonts.poppins(12, '#47bcc3')"
            class="text-decoration-underline"
          >
            Vamos lhe ajudar
          </a>
        </p>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import gFunc from "@/mixins/generic_functions";
import TextFieldComValidacao from "@/components/Global/TextFieldComValidacao";

export default {
  name: "CardLogin",

  components: {
    TextFieldComValidacao,
  },

  data() {
    return {
      gFunc: gFunc,
      show: false,
      user: "",
      password: "",
      logoIsLoaded: false,
      rules: {
        required: (value) => !!value || "Campo Obrigatório.",
        min: (v) => v.length >= 1 || "Mínimo 8 caracteres.",
      },
      loading: false,
      loginStatus: "",
    };
  },

  methods: {
    ...mapActions({
      signIn: "Login/signIn",
      inVacation: "Login/getInVacation",
      logOut: "Login/UserLogOut",
      setUnitysList: "User/setListUnity",
      setDepartmentList: "User/setListDepartment",
      setRolesList: "User/setListRole",
      getUserById: "User/getUserById"
    }),

    clickRecoverPass() {
      this.$emit("clickRecoverPass")
    },

    myHandler(e) {
      if (e.keyCode === 13) {
        this.onSignin();
      }
    },

    async onSignin() {
      if (!this.loading && this.$refs.form.validate()) {
        this.loginStatus = "";
        this.loading = true;
        let email = this.$refs.email.text;
        let password = this.$refs.password.text;

        this.loginStatus = await this.signIn({
          user: email,
          password: password,
        });
      }
      if (this.loginStatus === true) {
        this.$router.push("chat/service");

        await this.getUserById({
          token: this.userToken,
          domain: "[('id', '=', " + this.userId + ")]"
        })
      } else {
        this.logOut();
      }
      this.loading = false;
    },

    reset_password() {
      return window.location.replace(process.env.VUE_APP_RESET_PASSWORLD_LINK)
    }
  },

  computed: {
    ...mapGetters({
      userToken: "Login/getToken",
      loggedIn: "Login/getloggedIn",
      userId: 'Login/getUserId',
    }),

    urlIcon() {
      return process.env ? process.env.VUE_APP_BASE_URL : ''
    },
  },
};
</script>
<style scoped>
.logoAnimation {
  animation: zoom-in 0.5s ease;
  animation-iteration-count: 1;
}
@keyframes zoom-in {
  0% {
    transform: scale(0.2, 0.2);
  }
  100% {
    transform: scale(1, 1);
  }
}
.btnSignin:hover {
  -webkit-animation: iconAnimation 0.3s forwards; /* for less modern browsers */
  animation: iconAnimation 0.3s forwards;
}
@keyframes iconAnimation {
  0% {
    transform: scale(1, 1);
    background-color: #47bcc3;
  }
  100% {
    transform: scale(1.1, 1.1);
    background-color: #086e72;
  }
}
</style>
