<template>
  <div style="height: 95%; width: 95%">
    <v-container class="d-flex justify-center">
      <v-img
        src="@/assets/login/recover_pass.png"
        max-height="100"
        contain
        @load="logoIsLoaded = true"
        class="logoAnimation"
      />
      <v-layout
        v-if="!logoIsLoaded"
        style="width: 100%"
        align-center
        justify-center
      >
        <v-skeleton-loader height="150" width="150" type="card" />
      </v-layout>
    </v-container>

    <v-col class="px-12" cols="12">
      <v-divider />
    </v-col>

    <v-container class="text-left">
      <div class="title-text-div pt-2 mb-6">
        <pre>
          <p
            class="title-text"
            :style="gFunc.fonts.poppins(20, '#47bcc3', '600', '')"
          >
            Quase lá,
          </p>
        </pre>
        <p :style="gFunc.fonts.poppins(16, '#888', '400', '')" class="mt-1">
          defina uma nova senha de acesso.
        </p>
      </div>
      <v-form ref="form">
        <TextFieldComValidacao
          label="Senha"
          :rules="[
            gFunc.rules.required,
            rules.min,
            gFunc.rules.validatePassword,
          ]"
          password
          placeholder="••••••••••••"
          ref="password"
        />

        <TextFieldComValidacao
          label="Confirme à Senha"
          :rules="[
            gFunc.rules.required,
            rules.min,
            rules.equal,
            gFunc.rules.validatePassword,
          ]"
          password
          placeholder="••••••••••••"
          ref="confirmPassword"
        />

        <div class="mb-4">
          <span v-if="error" style="color: #fe5050">
            {{ error }}
          </span>
        </div>

        <v-layout justify-center>
          <v-btn
            @click="sendChangePassword()"
            :loading="loading"
            :disabled="loading"
            color="#47bcc3"
            rounded
            class="white--text text-capitalize font-weight-bold btn"
            height="40"
            width="220"
          >
            <span :style="gFunc.fonts.poppins(16, 'white')">
              Alterar senha
            </span>
            <v-icon color="white" class="pl-2"> check </v-icon>
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import gFunc from "@/mixins/generic_functions";
import TextFieldComValidacao from "@/components/Global/TextFieldComValidacao";

export default {
  name: "CardLogin",

  components: {
    TextFieldComValidacao,
  },

  props: {
    token: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      rules: {
        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres.",
        equal: (v) =>
          (this.$refs.password &&
            this.$refs.confirmPassword &&
            v === this.$refs.password.text &&
            v === this.$refs.confirmPassword.text) ||
          "As senhas não se coincidem.",
      },
      gFunc: gFunc,
      logoIsLoaded: false,
      loading: false,
      error: "",
    };
  },

  methods: {
    ...mapActions({
      postChangePassword: "Login/postChangePassword",
      signIn: "Login/signIn",
    }),

    goBack: () => this.$emit("goBack"),

    async sendChangePassword() {
      if (!this.loading && this.$refs.form.validate()) {
        this.loading = true;
        this.error = "";
        let password = this.$refs.password.text;
        let confirmPassword = this.$refs.confirmPassword.text;
        let result = await this.postChangePassword({
          token: this.token,
          password: password,
          confirm_password: confirmPassword,
        });
        if (result.retorno === true) {
          await this.signIn({
            user: result.data.email,
            password: password,
          });
          this.$router.push("/chat/service");
        } else {
          this.error = result.message;
        }
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.logoAnimation {
  animation: zoom-in 0.5s ease;
  animation-iteration-count: 1;
}
@keyframes zoom-in {
  0% {
    transform: scale(0.2, 0.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

.btn:hover {
  -webkit-animation: iconAnimation 0.3s forwards;
  animation: iconAnimation 0.3s forwards;
}

@keyframes iconAnimation {
  0% {
    transform: scale(1, 1);
    background-color: #47bcc3;
  }
  100% {
    transform: scale(1.1, 1.1);
    background-color: #086e72;
  }
}
</style>
