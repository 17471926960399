<template>
  <v-card class="secondarymenu elevation-0" color="transparent" rounded="0">
    <v-app-bar class="px-0" :color="Boolean(banner) ? banner['page_bg_color'] : 'white'" height="40">
      <v-app-bar-nav-icon
        v-if="gFunc.isMobile()"
        @click.stop="minMaxDrawer()"
        :style="`color: ${Boolean(banner) ? banner['page_text_color'] : '#C0C0C0'};`"
      />

      <v-spacer />

      <v-menu
        offset-y
        rounded="lg"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            style="position: relative;"
          >
            <v-avatar
              v-bind="attrs"
              v-on="on"
              height="30"
              width="30"
              size="30"
              class="elevation-1 d-flex justify-center align-center"
              color="primary"
              style="cursor: pointer;"
            >
              <span class="white--text headline text-h5"> {{ avatarName }} </span>
            </v-avatar>
            <div
              :class="{
                'customStatus': true
              }"
              :style="`background-color: ${status.color}`"
            />
          </div>
        </template>
        <v-list dense>
          <v-menu
            open-on-hover
            offset-x
            left
            rounded="lg"
            nudge-top="9"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                link
                v-bind="attrs"
                v-on="on"
                class="py-0"
              >
                <v-list-item-icon>
                  <v-icon>
                    arrow_back_ios
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  class="text-caption"
                >
                  Status
                </v-list-item-title>
              </v-list-item>
            </template>

            <v-list dense>
              <v-list-item
                v-for="(item, indexStatus) in agentStatus"
                :key="indexStatus"
                class="text-center"
                :style="'border-bottom: 1px solid rgba(0, 0, 0, 0.12);'"
                @click="setNewStatus(item)"
                link
              >
              <v-icon :style="`color: ${item.color};`" size = 12>
                circle
              </v-icon>
                <v-list-item-title
                  class="text-caption"
                >
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-divider class="pb-0" />
          <v-list-item
            link
            class="py-0"
          >
            <v-list-item-icon>
              <v-icon>
                person
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="text-caption"
            >
              Meu perfil
            </v-list-item-title>
          </v-list-item>
          <v-divider class="pb-0" />
          <v-list-item
            link
            class="py-0"
            @click="logout()"
          >
            <v-list-item-icon>
              <v-icon>
                logout
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="text-caption"
            >
              Sair
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </v-card>
</template>

<script>
import gVar from "@/mixins/global_variables";
import gFunc from "@/mixins/generic_functions";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "SecondaryMenu",

  data: () => ({
    gVar: gVar,
    gFunc: gFunc,
    drawer: true,
    menuModules: [],
  }),

  methods: {
    ...mapActions({
      userLogOut: "Login/UserLogOut",
      setUserGroup: "User/getUserGroup",
      getAgentStatus: "User/getAgentStatus",
    }),
    ...mapMutations({
      cleanChatStore: "ChatApi/CLEAN",
      SET_WS_STATUS: "Ws/SET_WS_STATUS"
    }),

    minMaxDrawer() {
      this.$emit("minMaxDrawer");
    },

    async logout() {
      let access_token = localStorage.getItem("accessToken")
      this.SET_WS_STATUS({
        status: false
      })
      this.userLogOut()
      this.$router.push("/")
      this.cleanChatStore()

      await this.$socket.sendObj({
        db_name: process.env.VUE_APP_DATA_BASE,
        access_token: access_token,
        action: "logout",
      });
    },
    setNewStatus(item) {
      try {
        this.$socket.sendObj({
          db_name: process.env.VUE_APP_DATA_BASE,
          access_token: localStorage.getItem("accessToken"),
          action: "set_agent_status",
          body: {
            status_id: item.id
          }
        });
      } catch (e) {
        const messageError = e.response ? e.response.data.message : e;
        this.SET_MESSAGE_ERROR({
          message: messageError,
        });
      }
    }
  },

  computed: {
    ...mapGetters({
      getToken: "Login/getToken",
      userName: "User/getUserName",
      banner: "Panel/getBanner",
      userAgentId: "User/getUserAgentId",
      agents: "Ws/getAgents",
      status: "Ws/getCurrentStatus",
      agentStatus: "User/getAgentStatusList",
    }),

    breadCrumbs() {
      return ["Atendimentos", "Tickets", "Gerenciamento"];
    },

    avatarName() {
      if (this.userName) {
        var firstLetter = this.userName.charAt(0);
        var lastLetter = "";
        var lastSpace = this.userName.lastIndexOf(" ");
        if (lastSpace > 0) {
          lastLetter = this.userName.substring(lastSpace + 1, lastSpace + 2);
        }
        return (firstLetter + lastLetter).toUpperCase();
      }
    },
  },
  async created(){
    const userToken = localStorage.getItem("accessToken")
    await this.getAgentStatus({token: userToken,})
  }
};
</script>
<style scoped>
.secondarymenu {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 999;
}
.customStatus {
  height: 8px;
  width: 8px;
  position: absolute;
  right: 0;
  bottom: -1px;
  border-radius: 100%;
}
</style>