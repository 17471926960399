var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'main d-flex justify-center align-center fill-height': true,
    'px-4': _vm.$vuetify.breakpoint.mdAndDown,
  },style:(`
          min-height: 100vh;
          background: ${_vm.cardLColor};
          background: -webkit-linear-gradient(bottom right, ${_vm.cardLColor}, ${_vm.cardRColor});
          background: -moz-linear-gradient(bottom right, ${_vm.cardLColor}, ${_vm.cardRColor});
          background: linear-gradient(to bottom right, ${_vm.cardLColor}, ${_vm.cardRColor});`)},[_c('v-card',{staticClass:"mainCard",attrs:{"height":_vm.mainCardSize.height,"width":_vm.mainCardSize.width,"elevation":"12","color":"white"}},[_c('v-layout',{staticClass:"fill-height",attrs:{"align-center":"","justify-center":""}},[_c('v-card',{staticClass:"cardLeft",style:(_vm.$vuetify.breakpoint.mdAndDown
            ? 'border-radius: 1rem !important;'
            : ''),attrs:{"height":_vm.$vuetify.breakpoint.lgAndUp ? '640px' : _vm.mainCardSize.height,"width":_vm.$vuetify.breakpoint.lgAndUp ? '55%' : _vm.mainCardSize.width,"color":"white","elevation":"0"}},[_c('v-layout',{staticClass:"fill-height",attrs:{"column":"","align-center":"","justify-center":""}},[(!_vm.showRecoverPass && !_vm.showChangePass)?_c('CardLogin',{on:{"clickRecoverPass":function($event){_vm.showRecoverPass = true}}}):_vm._e(),(_vm.showRecoverPass)?_c('CardRecoverPassword',{on:{"goBack":function($event){_vm.showRecoverPass = false}}}):_vm._e(),(_vm.showChangePass)?_c('CardChangePassword',{attrs:{"token":_vm.routerHash}}):_vm._e()],1)],1),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-card',{staticClass:"cardRight",attrs:{"height":_vm.bannerSize.height,"width":_vm.bannerSize.width,"elevation":"8"}},[_c('v-layout',{staticClass:"fill-height",attrs:{"align-end":"","justify-end":""}},[_c('div',{staticClass:"px-2"},[_c('v-img',{staticClass:"intraflowAnimation",staticStyle:{"opacity":"0"},attrs:{"src":require("@/assets/login/intraflow_small.png"),"contain":"","height":"150","width":"170"}})],1)])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }