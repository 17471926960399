<template>
  <div class="d-block">
    <SecondaryMenu @minMaxDrawer="drawer = !drawer" />
    <v-navigation-drawer
      v-model="drawer"
      class="sidebar fill-height"
      app
      width="220"
      :mini-variant="showSidebar"
    >
      <div
        :style="`height: 40px; width: 100%; background-color: ${
          Boolean(banner) ? banner['page_bg_color'] : 'white'
        }; border-bottom: 1px solid rgba(0, 0, 0, 0.12)`"
      />
      <v-list
        dense
        :style="`height: calc(100% - 40px); background-color: ${
          Boolean(banner) ? banner['page_bg_color'] : 'white'
        }; overflow: hidden;`"
        class="py-0"
      >
        <v-list-item-group v-model="selectedItem" class="fill-height">
          <div class="d-flex flex-column justify-end fill-height">
            <v-list-item
              v-for="(page, index) in agentMenuAccess"
              :key="index"
              :class="
                index === 3
                  ? selectedItem === index
                    ? 'font-weight-bold mt-auto'
                    : 'mt-auto'
                  : ''
              "
              :style="
                index >= 3
                  ? 'border-top: 1px solid rgba(0, 0, 0, 0.12); max-height: 40px; padding-left: 15px;'
                  : 'border-bottom: 1px solid rgba(0, 0, 0, 0.12); max-height: 40px; padding-left: 15px;'
              "
              :disabled="index === 3"
              @click="currentRoute != page.to ? goToRouter(page.to) : false"
              link
            >
              <v-list-item-icon
                class="d-flex align-center my-0"
                style="height: 100% !important"
              >
                <v-icon
                  v-if="index === 3"
                  :color="wsStatus ? 'green' : 'red'"
                >
                  mdi-connection
                </v-icon>
                <v-icon
                    v-else
                    class="d-flex align-center"
                    :color="
                      Boolean(banner) ? banner['page_text_color'] : '#1d1d1d'
                    "
                  >
                    {{ page.icon }}
                  </v-icon>
              </v-list-item-icon>

              <v-list-item-content
                v-if="index != 3"
              >
                <v-list-item-title
                  :style="`color: ${
                    Boolean(banner) ? banner['page_text_color'] : '#1d1d1d'
                  }`"
                >
                  {{ page.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
      <v-btn
        icon
        @click="showSidebar = !showSidebar"
        small
        class="my-auto showSidebarBtn"
        elevation="1"
        :style="`background-color: ${
          Boolean(banner) ? banner['page_bg_color'] : 'white'
        }`"
      >
        <v-icon
          :color="Boolean(banner) ? banner['page_text_color'] : '#1d1d1d'"
        >
          {{ !showSidebar ? "chevron_left" : "chevron_right" }}
        </v-icon>
      </v-btn>
    </v-navigation-drawer>
  </div>
</template>

<script>
import gVar from "@/mixins/global_variables";
import gFunc from "@/mixins/generic_functions";
import { mapActions, mapGetters } from "vuex";
import SecondaryMenu from "./SecondaryMenu.vue";

export default {
  name: "Sidebar",

  components: {
    SecondaryMenu,
  },

  data: () => ({
    gVar: gVar,
    gFunc: gFunc,
    drawer: null,
    modules: null,
    selectedItem: 0,
    showSidebar: true,
  }),

  methods: {
    async goToRouter(route) {
      this.$router.push(route);
    },
  },

  computed: {
    ...mapGetters({
      getToken: "Login/getToken",
      userGroup: "User/getUserGroup",
      banner: "Panel/getBanner",
      wsStatus: "Ws/getWsStatus"
    }),
    agentMenuAccess() {
      return [
        {
          title: "Atendimentos",
          to: "/chat/service",
          icon: "support_agent",
        },
        {
          title: "Tickets",
          to: "/chat/ticket",
          icon: "receipt_long",
        },
        {
          title: "Gerenciamento",
          to: "/chat/management",
          icon: "route",
        },
        {
          title: "",
          to: "",
          icon: "",
        },
        {
          title: "Configuração",
          to: "/chat/setting",
          icon: "settings",
        },
      ];
    },
    currentRoute() {
      return this.$route.path;
    },
  },
  mounted() {
    const currentRoutePath = this.$router.currentRoute.path;
    switch (currentRoutePath) {
      case "/chat/service":
        this.selectedItem = 0;
        break;
      case "/chat/ticket":
        this.selectedItem = 1;
        break;
      case "/chat/management":
        this.selectedItem = 2;
        break;
      case "/chat/setting":
        this.selectedItem = 3;
        break;
    }
  },
};
</script>

<style scoped>
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 250px;
  overflow: visible;
}
.showSidebarBtn {
  z-index: 2;
  background-color: white;
  position: absolute;
  right: 3%;
  top: 50%;
  margin-right: -14px;
}
</style>
