var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ApplicantCard',{attrs:{"title":'Detalhes do solicitante',"hasOptions":false,"options":[
    {
      icon: 'person_add',
      title: 'Criar parceiro',
    },
  ],"cardHeight":40,"diffHeight":62.5}},[_c('v-card-text',{class:{
      'py-2': true,
      'px-0': _vm.$vuetify.breakpoint.mdAndDown,
    }},[_c('v-container',{class:{
        'px-10': _vm.$vuetify.breakpoint.lgAndUp,
        'px-0': _vm.$vuetify.breakpoint.mdAndDown,
        'pt-0': true,
      }},[_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-col',{class:{
            'mt-3': true,
            'pl-10': _vm.$vuetify.breakpoint.lgAndUp,
          },attrs:{"cols":"5"}},[_c('span',{staticClass:"text-caption"},[_vm._v(" Telefone ")])]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","disabled":"","height":"20","hide-details":"","color":Boolean(_vm.banner) ? _vm.banner['page_text_color'] : '#1d1d1d',"background-color":Boolean(_vm.banner) ? _vm.banner['page_text_color'] : 'white'}}),_c('v-sheet',{attrs:{"height":"20","rounded":"md"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }