import Vue from 'vue'
import store from "@/store";
import VueNativeSock from 'vue-native-websocket'

Vue.use(
    VueNativeSock,
    process.env.VUE_APP_WEBSOCKET_SERVER, {
        format: 'json',
        connectManually: true,
        reconnection: true,
        reconnectionAttempts: 9999,
        reconnectionDelay: 5000
    }
)