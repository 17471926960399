import axios from "axios";
import store from "@/store";
import router from "@/router";
import Swal from "sweetalert2";

const initialState = () => ({
  channels: [],
  qrCodeLoading: false,
  loadingChannels: false
});
const state = initialState();
const getters = {
  getQrCodeLoading: (state) => state.qrCodeLoading,
  getLoadingChannels: (state) => state.loadingChannels,
  getChannels: (state) => state.channels,
};
const actions = {
  async setUserChannels(context, { token }) {
    context.commit("LOAD_CHANNELS", {
      load: true,
    });
    await axios
      .get(process.env.VUE_APP_BASE_URL + "/get/chat/api/user_channels", {
        headers: {
          "Access-Token": token,
        },
      })
      .then((response) => {
        if (response["status"] == 200) {
          context.commit("SET_USER_CHANNELS", {
            result: response.data.data,
          });
        }
      })
      .catch((e) => {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
    context.commit("LOAD_CHANNELS", {
      load: false,
    });
  },
  async setQrCodeByChannelId(context, { token, channelId }) {
    let result = null;
    context.commit("LOAD_QR_CODE", {
      load: true,
    });
    await axios
      .get(
        process.env.VUE_APP_BASE_URL +
          "/get/chat/api/qr_code_by_channel_id/" +
          channelId,
        {
          headers: {
            "Access-Token": token,
          },
        }
      )
      .then((response) => {
        if (response["status"] == 200) {
          result = response.data.data;
          context.commit("SET_CHANNEL_QR_CODE", {
            result: result,
            channelId: channelId,
          });
        }
      })
      .catch((e) => {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
    context.commit("LOAD_QR_CODE", {
      load: false,
    });
    return result;
  },
};
const mutations = {
  SET_USER_CHANNELS(state, { result }) {
    state.channels = result;
  },
  SET_CHANNEL_QR_CODE(state, { result, channelId }) {
    const channelIndex = state.channels.findIndex(
      (channel) => channel.channel_id === channelId
    );
    if (channelIndex > -1) {
      if (state.channels[channelIndex].is_ready === false && result.is_ready === true) {
        Swal.fire({
          icon: 'success',
          html: "Leitura do Qr Code realizada.<br><br>Deseja ir para a página de atendimentos?",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then((result) => {
          if (result.isConfirmed) {
            router.push("/chat/service");
          }
        });
      }
      state.channels[channelIndex].qr_code = result.qr_code;
      state.channels[channelIndex].is_ready = result.is_ready;
    }
  },
  LOAD_QR_CODE(state, { load }) {
    state.qrCodeLoading = load;
  },
  LOAD_CHANNELS(state, { load }) {
    state.loadingChannels = load;
  },
  CLEAN(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};
export default {
  actions,
  state,
  getters,
  mutations,
  namespaced: true,
};
