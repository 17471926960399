<template>
  <v-card height="100%" rounded="lg">
    <v-card-title
      class="d-flex justify-space-between"
    >
      <span>
        Fila de espera
      </span>
      <div>
        <v-menu offset-y rounded="lg" v-if="isSupervisor">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              plain
              class="text-capitalize"
            >
              <v-icon
                class="mr-1"
                :color="
                  Boolean(banner) ? banner['page_text_color'] : '#1d1d1d'
                "
              >
                filter_list
              </v-icon>
              Times
            </v-btn>
          </template>
          <v-list dense>
            <div v-for="(item, index) in teams" :key="index">
              <v-list-item link class="py-0" @click="setTeam(item)">
                <v-list-item-title class="text-caption">
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
              <v-divider
                v-if="teams.length > 1 && index < teams.length - 1"
              />
            </div>
          </v-list>
        </v-menu>
      </div>
    </v-card-title>
    <v-card-text style="height: calc(100% - 50px)">
      <div class="d-flex flex-column justify-end fill-height">
        <v-card elevation="1" class="mb-4">
          <SupervisorQueue v-if="isSupervisor" :page="page" :currentTeam="currentTeam" :itemsPerPage="10" />
          <Queue v-else :page="page" :currentTeam="currentTeam" :itemsPerPage="10" />
        </v-card>

        <v-pagination
          v-if="pageCount && pageCount > 0"
          class="mt-auto"
          :color="Boolean(banner) ? banner['page_bg_color'] : 'primary'"
          v-model="page"
          :length="pageCount && pageCount > 0 ? pageCount : 0"
        />
        <div v-else class="mt-auto" />
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import Queue from "@/components/Global/Queue.vue";
import SupervisorQueue from "@/components/Global/SupervisorQueue.vue"
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "QueueCard",
  components: {
    Queue,
    SupervisorQueue
  },
  data: () => ({
    page: 1,
    currentTeam: 0,
  }),
  methods: {
    ...mapMutations({
      SET_CURRENT_QUEUE: "Ws/SET_CURRENT_QUEUE",
      SET_CURRENT_TEAM: "User/SET_CURRENT_TEAM",
      SET_MESSAGE_ERROR: "Error/SET_MESSAGE_ERROR",
    }),
    async setTeam(item) {
      this.SET_CURRENT_TEAM({teamId: item.id})
      this.SET_CURRENT_QUEUE({teamId: item.id})
      await this.setWsQueue()
    },
    async setWsQueue() {
      await this.setAllAgents()
      await this.setQueue()
    },
    async setWsAction(action, teamId, chatId) {
      switch (action) {
        case 1:
          await this.takeChat(teamId, chatId);
      }
    },
    async takeChat(teamId, chatId) {
      try {
        this.$socket.sendObj({
          db_name: process.env.VUE_APP_DATA_BASE,
          access_token: this.userToken,
          action: "new_agent_chat",
          body: {
            team_id: teamId,
            ticket_id: chatId,
          },
        });
      } catch (e) {
        const messageError = e.response ? e.response.data.message : e;
        this.SET_MESSAGE_ERROR({
          message: messageError,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      loadingQueue: "Management/getLoadingQueue",
      queue: "Ws/getQueue",
      queueMenu: "Management/getQueueMenu",
      banner: "Panel/getBanner",
      userToken: "Login/getToken",
      isSupervisor: "User/getIsSupervisor",
      teams: "User/getTeams"
    }),
    teamQueue() {
      if (
        Boolean(
          this.queue[this.currentTeam] &&
            this.queue[this.currentTeam].team.tickets.length > 0
        )
      ) {
        return this.queue[this.currentTeam].team.tickets.filter(
          (ticket) => ticket.state === "draft"
        );
      } else {
        return [];
      }
    },
    pageCount() {
      if (
        Boolean(
          this.queue[this.currentTeam] &&
            this.queue[this.currentTeam].team.tickets.length > 0
        )
      ) {
        const ticketsInQueue = this.queue[this.currentTeam].team.tickets.filter(
          (ticket) => ticket.state === "draft"
        );
        const numberOfPages = ticketsInQueue.length / 10;
        return numberOfPages > 0 ? Math.ceil(numberOfPages) : 1;
      } else {
        return 0;
      }
    },
  },
};
</script>
