import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

// import the auto exporter
import modules from './modules';

Vue.use(Vuex);
const debug = true;

export default new Vuex.Store({
  modules, // all your modules automatically imported :)
  strict: debug,
  plugins: debug ? [createLogger()] : [], // set logger only for development
});
