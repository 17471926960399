<template>
  <v-container fluid class="fill-height">
    <div class="d-flex flex-column justify-end fill-height" style="width: 100%">
      <p class="text-h4">Canais</p>
      <v-card class="mb-4">
        <v-data-table
          no-results-text="Não há registro."
          no-data-text="Não há registro."
          loading-text="Carregando canais..."
          :loading="loadingChannels"
          :expanded.sync="expanded"
          :items-per-page="10"
          hide-default-footer
          :items="channels"
          :page.sync="page"
          single-expand
          @click:row="toggleRow"
          item-key="channel_id"
          show-expand
          style="cursor: pointer"
          :headers="[
            {
              text: 'Canal',
              value: 'name',
            },
            {
              text: '',
              value: 'data-table-expand',
            },
          ]"
        >
          <template v-slot:[`item.name`]="{ item }">
            <td v-show="item">
              <p class="mb-0 pb-0">
                <v-icon>
                  {{ setChannelOrigin(item.channel_origin) }}
                </v-icon>
                -
                {{ item.name }}
              </p>
            </td>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td
              class="text-center mt-2"
              style="width: 100vw"
              v-show="item"
              :colspan="headers.length"
            >
              <div v-if="!item.is_ready" class="d-flex justify-center mt-3 flex-column align-center">
                <v-img v-if="item.qr_code" width="350px" :src="item.qr_code"></v-img>
                <p>
                  {{ qrCodeCountdown }}
                </p>
              </div>
              <span v-else> Qr Code lido </span>
            </td>
          </template>
        </v-data-table>
      </v-card>

      <v-pagination
        v-if="pageCount && pageCount > 0"
        class="mt-auto"
        :color="Boolean(banner) ? banner['page_bg_color'] : 'primary'"
        v-model="page"
        :length="pageCount && pageCount > 0 ? pageCount : 0"
      />
      <div v-else class="mt-auto" />
    </div>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import QrCode from "../Global/QrCode.vue";
export default {
  name: "MainComponent",
  components: {
    QrCode,
  },
  data: () => ({
    qrCodeInterval: null,
    qrCodeCountdown: 5,
    channel: null,
    page: 1,
    expanded: [],
  }),
  watch: {
    expanded: function (newValue, oldValue) {
      if (Boolean(newValue && newValue.length > 0)) {
        clearInterval(this.qrCodeInterval);

        this.qrCodeCountdown = 10;

        if (newValue[0]["is_ready"] === false) {
          this.qrCodeInterval = setInterval(() => {
            this.qrCodeCountdown--;
            if (Boolean(this.qrCodeCountdown === 0)) {
              this.qrCodeCountdown = 10;
              this.setQrCodeChannel(newValue[0]["channel_id"]);
            }
          }, 1000);
        }
      } else {
        clearInterval(this.qrCodeInterval);
      }
    },
  },
  methods: {
    ...mapActions({
      setQrCodeByChannelId: "ChatApi/setQrCodeByChannelId",
      setUserChannels: "ChatApi/setUserChannels",
    }),
    toggleRow(item) {
      const index = this.expanded.findIndex(
        (el) => el.channel_id == item.channel_id
      );
      if (index === -1) {
        this.expanded.push(item);
      } else {
        this.expanded.splice(index, 1);
      }
    },
    setChannelOrigin(channel_origin) {
      switch (channel_origin) {
        case "whatsapp":
          return "mdi-whatsapp";
      }
    },
    async setQrCodeChannel(channelId) {
      await this.setQrCodeByChannelId({
        token: this.userToken,
        channelId: channelId,
      });
    },
  },
  computed: {
    ...mapGetters({
      userToken: "Login/getToken",
      loadingChannels: "ChatApi/getLoadingChannels",
      channels: "ChatApi/getChannels",
      banner: "Panel/getBanner",
    }),
    pageCount() {
      if (this.channels.length > 0) {
        var numberOfPages = this.channels.length / 10;
        return Math.ceil(numberOfPages);
      } else {
        return 0;
      }
    },
  },
  async mounted() {
    await this.setUserChannels({ token: this.userToken });
  },
  beforeDestroy() {
    clearInterval(this.qrCodeInterval);
  },
};
</script>
