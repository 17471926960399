/**
 * Automatically imports all the modules and exports as a single module object
 */
const requireModule = require.context(".", false, /\.store\.js$/);
const dinamicModule = require.context(
  "../../components/",
  true,
  /\.store\.js$/
);
const modules = {};

requireModule.keys().forEach((filename) => {
  // create the module name from fileName
  // remove the store.js extension and capitalize
  const moduleName = filename
    .replace(/(\.\/|\.store\.js)/g, "")
    .replace(/^\w/, (c) => c.toUpperCase());

  modules[moduleName] =
    requireModule(filename).default || requireModule(filename);
});

dinamicModule.keys().forEach((filename) => {
  let path = filename.replace("./", "");
  const moduleName = filename
    .replace(/(\.\/|\.store\.js)/g, "")
    .replace(/^\w/, (c) => c.toUpperCase());
  let findKey = Object.keys(modules).find(
    (el) => el.toLowerCase() === moduleName.split("/store/")[1].toLowerCase()
  );
  if (findKey) {
    alert(
      `Erro na importação do store: \n Já existe um store com o nome "${findKey.toLowerCase()}.store.js"`
    );
  } else {
    modules[moduleName.split("/store/")[1]] =
      require(`../../components/${path}`).default ||
      require(`../../components/${path}`);
  }
});
export default modules;
