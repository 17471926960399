<template>
  <div>
    <v-overlay :value="showOverlay" absolute opacity="0.3">
      <v-progress-circular
        :indeterminate="true"
        :rotate="rotate"
        :size="size"
        :width="width"
        :color="color"
        :opacity="opacity"
      />
    </v-overlay>

    <Snackbar :show="showSnackbar" :color="snackBarColor" :mensage="mensage" />
  </div>
</template>

<script>
import Snackbar from "@/components/Global/Snackbar.vue";

export default {
  name: "Loader",

  components: {
    Snackbar,
  },

  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    occurredError: {
      type: Boolean,
      required: true,
      default: false,
    },
    rotate: {
      type: Number,
      required: false,
      default: 50,
    },
    size: {
      type: Number,
      required: false,
      default: 60,
    },
    width: {
      type: Number,
      required: false,
      default: 6,
    },
    color: {
      type: String,
      required: false,
      default: "light-blue",
    },
    opacity: {
      type: Number,
      required: false,
      default: 0.46,
    },
  },

  data() {
    return {
      showSnackbar: false,
      snackBarColor: "",
      mensage: "",
    };
  },

  watch: {
    occurredError: function () {
      this.showSnackbar = this.occurredError;
      this.snackBarColor = "red";
      this.mensage = "ocorreu um erro";
    },
  },

  computed: {
    showOverlay() {
      return this.isLoading && !this.occurredError;
    },
  },
};
</script>
