var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-block"},[_c('SecondaryMenu',{on:{"minMaxDrawer":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-navigation-drawer',{staticClass:"sidebar fill-height",attrs:{"app":"","width":"220","mini-variant":_vm.showSidebar},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{style:(`height: 40px; width: 100%; background-color: ${
        Boolean(_vm.banner) ? _vm.banner['page_bg_color'] : 'white'
      }; border-bottom: 1px solid rgba(0, 0, 0, 0.12)`)}),_c('v-list',{staticClass:"py-0",style:(`height: calc(100% - 40px); background-color: ${
        Boolean(_vm.banner) ? _vm.banner['page_bg_color'] : 'white'
      }; overflow: hidden;`),attrs:{"dense":""}},[_c('v-list-item-group',{staticClass:"fill-height",model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},[_c('div',{staticClass:"d-flex flex-column justify-end fill-height"},_vm._l((_vm.agentMenuAccess),function(page,index){return _c('v-list-item',{key:index,class:index === 3
                ? _vm.selectedItem === index
                  ? 'font-weight-bold mt-auto'
                  : 'mt-auto'
                : '',style:(index >= 3
                ? 'border-top: 1px solid rgba(0, 0, 0, 0.12); max-height: 40px; padding-left: 15px;'
                : 'border-bottom: 1px solid rgba(0, 0, 0, 0.12); max-height: 40px; padding-left: 15px;'),attrs:{"disabled":index === 3,"link":""},on:{"click":function($event){_vm.currentRoute != page.to ? _vm.goToRouter(page.to) : false}}},[_c('v-list-item-icon',{staticClass:"d-flex align-center my-0",staticStyle:{"height":"100% !important"}},[(index === 3)?_c('v-icon',{attrs:{"color":_vm.wsStatus ? 'green' : 'red'}},[_vm._v(" mdi-connection ")]):_c('v-icon',{staticClass:"d-flex align-center",attrs:{"color":Boolean(_vm.banner) ? _vm.banner['page_text_color'] : '#1d1d1d'}},[_vm._v(" "+_vm._s(page.icon)+" ")])],1),(index != 3)?_c('v-list-item-content',[_c('v-list-item-title',{style:(`color: ${
                  Boolean(_vm.banner) ? _vm.banner['page_text_color'] : '#1d1d1d'
                }`)},[_vm._v(" "+_vm._s(page.title)+" ")])],1):_vm._e()],1)}),1)])],1),_c('v-btn',{staticClass:"my-auto showSidebarBtn",style:(`background-color: ${
        Boolean(_vm.banner) ? _vm.banner['page_bg_color'] : 'white'
      }`),attrs:{"icon":"","small":"","elevation":"1"},on:{"click":function($event){_vm.showSidebar = !_vm.showSidebar}}},[_c('v-icon',{attrs:{"color":Boolean(_vm.banner) ? _vm.banner['page_text_color'] : '#1d1d1d'}},[_vm._v(" "+_vm._s(!_vm.showSidebar ? "chevron_left" : "chevron_right")+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }