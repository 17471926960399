<template>
    <v-snackbar v-model=showSnackBar :color="color" top right>
            <span
                v-html="mensage"
            />

            <v-btn dark text @click="closeSnackBar()">
                fechar
            </v-btn>
        </v-snackbar>
</template>

<script>

export default {
    props: {
        show: {
            type: Boolean,
            required: false,
            default: false
        },
        mensage: {
            type: String,
            required: false,
        },
        color: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            showSnackBar: false,
        };
    },
    watch:{
        show: function() {
            this.showSnackBar = this.show;
        },

        showSnackBar: function() {
            if (this.showSnackBar == false){
                this.$emit('closeSnackBar')
            }
        },
    },
    methods: {
        closeSnackBar() {
            this.showSnackBar = false
        },
    }
};
</script>
