<template>
  <v-container fluid class="fill-height">
    <v-row>
      <v-col
        :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6"
        :style="$vuetify.breakpoint.mdAndDown ? '' : 'flex: 0 0 50% !important; max-width: 50% !important'"
      >
        <ChatCard />
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.mdAndDown ? 12 : 5"
        :order="$vuetify.breakpoint.mdAndDown ? 3 : 2"
        :style="$vuetify.breakpoint.mdAndDown ? '' : 'flex: 0 0 44% !important; max-width: 44% !important'"
      >
        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            class="pb-4"
            v-if="multipleCards.some(el => el === 0)"
          >
            <ApplicantDetailsCard />
          </v-col>
          <v-col
            cols="12"
            class="pt-0"
            v-if="multipleCards.some(el => el === 1)"
          >
            <ApplicantQueueCard />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.mdAndDown ? 12 : 1"
        :order="$vuetify.breakpoint.mdAndDown ? 2 : 3"
        :style="$vuetify.breakpoint.mdAndDown ? '' : 'flex: 0 0 6% !important; max-width: 6% !important'"
      >
        <SideCards />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ChatCard from "@/components/Chat/MainComponent.vue";
import ApplicantDetailsCard from "@/components/ApplicantDetails/MainComponent.vue";
import ApplicantTicketsCard from "@/components/ApplicantTickets/MainComponent.vue";
import ApplicantQueueCard from "@/components/ApplicantQueue/MainComponent.vue"
import SideCards from "../Global/SideCards.vue";
import { mapGetters } from 'vuex';
export default {
  name: "MainComponent",
  components: {
    ChatCard,
    ApplicantDetailsCard,
    ApplicantTicketsCard,
    ApplicantQueueCard,
    SideCards
  },
  computed: {
    ...mapGetters({
      multipleCards: "Panel/getMultipleCards"
    })
  }
};
</script>
